import { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

import { formatBytes } from 'lib/util';
import { profileCircleClasses } from 'styles/image';
import { ReactComponent as UploadIcon } from 'Assets/icons/cloud-upload.svg';
import { isTooSmall } from 'lib/image';

import type { ImageValidationError, NodeOrString } from 'types';
import { BlueprintOutlineButton } from './BlueprintOutlineButton';

type ImageUploadFieldProps = {
  descriptor: string;
  buttonText?: string;
  className?: string;
  roundedPreview?: boolean;
  existingUrl?: string;
  inputName?: string;
  value?: File | undefined;
  onChange?: (newFile: File | undefined) => void;
  setValidationError?: (hasError: boolean) => void;
  preExistingValidationError?: ImageValidationError;
  saving?: boolean;
  resetError?: false;
  dataTestId?: string;
  disabled?: boolean;
};

type SeverityType = 'error' | 'warning';

const validationSeverityMap: {
  [key: string]: SeverityType;
} = {
  notTotalExpertWhitelistedDomain: 'error',
  disallowedFileExtension: 'error',
  invalidFilename: 'error',
  sizeTooSmall: 'warning',
  sizeTooBig: 'error',
  typeMismatchGif: 'error',
  typeMismatchJpg: 'error',
  typeMismatchPng: 'error',
};

const getSeverity = (validationItem: ImageValidationError): SeverityType =>
  validationSeverityMap[validationItem.uniqueId];

const DoesNotPreventUploadNotice = () => (
  <span className="tw-text-gray-ravenswood"> (Does not prevent upload)</span>
);

const ImageUploadField = (props: ImageUploadFieldProps) => {
  const {
    className = '',
    descriptor = '',
    roundedPreview = false,
    buttonText = 'Browse files',
    existingUrl = '',
    inputName = '',
    value: fileInputValue,
    onChange = () => {},
    setValidationError = () => {},
    preExistingValidationError,
    saving = false,
    dataTestId = '',
    disabled = false,
  } = props;

  const fileInput = useRef<HTMLInputElement>(null);

  const [previewUrl, setPreviewUrl] = useState(existingUrl);
  const [previewAlt, setPreviewAlt] = useState(
    existingUrl ? 'Existing image' : ''
  );

  const [validationErrors, setValidationErrors] = useState<
    ImageValidationError[]
  >([]);
  const [alertMessage, setAlertMessage] = useState<NodeOrString>('');
  const [saveInitiated, setSaveInitiated] = useState(false);
  const [uploadRestricted, setUploadRestricted] = useState(false);

  const [preExistingInitiated, setPreExistingInitiated] = useState(false);

  useEffect(() => {
    if (saving) {
      setSaveInitiated(true);
    }
  }, [saving]);

  useEffect(() => {
    setValidationError(uploadRestricted);
  }, [setValidationError, uploadRestricted]);

  useEffect(() => {
    if (existingUrl) {
      if (!preExistingInitiated) {
        const errors: ImageValidationError[] = [];
        if (preExistingValidationError) {
          errors.push(preExistingValidationError);
          setValidationErrors(errors);
          setPreExistingInitiated(true);
        }
      }
      setPreviewUrl(existingUrl);
      setPreviewAlt(existingUrl ? 'Existing image' : '');
    }
  }, [existingUrl, preExistingInitiated, preExistingValidationError]);

  useEffect(() => {
    let preventUpload = validationErrors.length > 0;
    if (validationErrors.length) {
      if (validationErrors.length === 1) {
        setAlertMessage(
          <>
            <span
              className={classNames({
                'tw-text-red-critical':
                  getSeverity(validationErrors[0]) === 'error',
                'tw-text-warning-dark':
                  getSeverity(validationErrors[0]) === 'warning',
              })}
            >
              {validationErrors[0].message}
            </span>
            {getSeverity(validationErrors[0]) === 'warning' && (
              <DoesNotPreventUploadNotice />
            )}
          </>
        );
        if (validationErrors[0].uniqueId === 'sizeTooSmall') {
          preventUpload = false;
        }
      } else {
        const itemsSortedBySeverity = validationErrors.sort((a, b) => {
          return getSeverity(a) > getSeverity(b) ? 1 : -1;
        });
        setAlertMessage(
          <ul className="tw-ml-3">
            {itemsSortedBySeverity.map((err) => (
              <li
                className={classNames('tw-list-disc tw-whitespace-normal', {
                  'tw-text-red-critical': getSeverity(err) === 'error',
                  'tw-text-warning-dark marker:tw-text-warning-dark':
                    getSeverity(err) === 'warning',
                })}
                key={err.uniqueId}
              >
                {err.message}
                {getSeverity(err) === 'warning' && (
                  <DoesNotPreventUploadNotice />
                )}
              </li>
            ))}
          </ul>
        );
      }
    } else {
      setPreviewAlt(fileInputValue?.name || '');
      setAlertMessage('');
    }
    setUploadRestricted(preventUpload);
  }, [fileInputValue, validationErrors]);

  const SVG_FILE_TYPES = new Set(['.svg', '.svgz', 'image/svg+xml']);

  useEffect(() => {
    const validateImage = async (file: File) => {
      setAlertMessage('');
      const errors: ImageValidationError[] = [];
      if (file.type.startsWith('image/')) {
        if (SVG_FILE_TYPES.has(file.type)) {
          errors.push({
            message: <>SVG files are not allowed.</>,
            uniqueId: 'notImage',
          });
        } else {
          if (isTooSmall(file.size)) {
            errors.push({
              message: (
                <>
                  <strong>{formatBytes(file.size)}</strong> is less than the
                  recommended size (50 KB).
                </>
              ),
              uniqueId: 'sizeTooSmall',
            });
          }
        }
      } else {
        errors.push({
          message: <>Only images can be uploaded.</>,
          uniqueId: 'notImage',
        });
      }
      setValidationErrors(errors);
    };

    if (!saveInitiated) {
      setPreviewUrl('');
      setPreviewAlt('');

      if (!fileInputValue && fileInput?.current?.value) {
        fileInput.current.value = '';
      }
      if (fileInputValue !== undefined) {
        setPreviewUrl(window.URL.createObjectURL(fileInputValue));
        validateImage(fileInputValue);
      } else if (existingUrl) {
        setPreviewUrl(existingUrl);
        setPreviewAlt('Existing image');
      }
      if (!fileInputValue) {
        setValidationErrors([]);
      }
    }

    return function cleanup() {
      if (fileInputValue) {
        window.URL.revokeObjectURL(previewUrl);
      }
    };
  }, [fileInputValue]);

  const profileNonCircleClassNames =
    'tw-object-contain tw-h-10 tw-w-auto tw-object-left';
  const inputId = `image-upload-${inputName}`;
  return (
    <div className={`flex flex-row gap-5 ${className}`}>
      <div className="flex flex-col justify-center w-3/5">
        <div>
          <input
            ref={fileInput}
            type="file"
            id={inputId}
            name={inputName}
            hidden
            accept="image/*"
            onChange={(event) => {
              const newFile = event?.target?.files?.[0];
              onChange(newFile);
              if (newFile && !newFile.type.startsWith('image/')) {
                // @ts-ignore
                event.target.value = null;
              }
            }}
            data-testid={dataTestId}
            disabled={disabled}
          />
          <BlueprintOutlineButton
            size="small"
            startIcon={
              !fileInputValue && !existingUrl ? (
                <UploadIcon style={{ fontSize: '1.5rem' }} />
              ) : null
            }
            disabled={disabled}
            color={
              validationErrors.length &&
              validationErrors.filter((item) => getSeverity(item) === 'error')
                .length
                ? 'error'
                : 'secondary'
            }
            onClick={() => {
              if (fileInput?.current) {
                fileInput.current.click();
              }
            }}
          >
            {fileInputValue || existingUrl ? (
              <img
                className={classNames(
                  `overflow-hidden object-center text-center leading-5 ${
                    roundedPreview
                      ? classNames(profileCircleClasses(32))
                      : profileNonCircleClassNames
                  }`,
                  {
                    'tw-saturate-0': disabled,
                    'tw-brightness-125': disabled,
                  }
                )}
                alt={previewAlt}
                src={previewUrl}
              />
            ) : (
              buttonText
            )}
          </BlueprintOutlineButton>
        </div>
        <div className="my-2 text-sm">{descriptor}</div>
        {alertMessage && fileInputValue && (
          <div className="block mb-3 text-xs text-red-error">
            {alertMessage}
          </div>
        )}
      </div>
    </div>
  );
};

export { ImageUploadField };
