import { ApiResponse, JsonApiType, LoanApiType } from 'types';
import { requestWithTimeoutAndOktaLogin } from './util';
import { inLocalTestModeByEnvVar } from 'lib/util';
import { testLoans } from 'lib/test-data/test-data';

interface AllLoansResponse extends ApiResponse {
  loans?: LoanApiType[];
}

interface JsonApiLoan extends JsonApiType {
  attributes: LoanApiType;
}

const useTestBorrowerConsent = inLocalTestModeByEnvVar('BORROWER_CONSENT');

const formatJsonApiLoans = (loan: JsonApiLoan[]): LoanApiType[] =>
  loan.map((item: JsonApiLoan) => {
    const loan = item.attributes;
    if (
      !loan.hasOwnProperty('consent') ||
      typeof useTestBorrowerConsent === 'boolean'
    ) {
      loan.consent = {
        borrower:
          typeof useTestBorrowerConsent === 'boolean'
            ? useTestBorrowerConsent
              ? 1
              : loan?.consent?.borrower
            : null,
      };
    }
    return loan;
  });

const useTestLoanData = inLocalTestModeByEnvVar('LOANS');

async function allLoans(): Promise<AllLoansResponse> {
  if (useTestLoanData) {
    return {
      ok: true,
      loans: testLoans(),
    };
  }
  try {
    const response = await requestWithTimeoutAndOktaLogin('/v1/loans');
    if (!response.response?.ok || response.response.status !== 200) {
      throw response.responseObj;
    }

    let loans: LoanApiType[] = [];
    if (Array.isArray(response.responseObj?.data)) {
      loans = formatJsonApiLoans(response.responseObj.data);
    }
    return {
      ok: true,
      loans,
    };
  } catch (error) {
    return {
      ok: false,
      error,
    };
  }
}

export { allLoans };
