import { useCallback, useEffect, useMemo, useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import Paper, { PaperProps } from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import classNames from 'classnames';

import type { MLSAssociation, OptionProps } from 'types';
import { muiAutocompleteStyles, muiInputOutlinedStyles } from 'styles/mui';
import { ReactComponent as SearchIcon } from 'Assets/icons/magnifying-glass-search.svg';
import useCurrentUser from 'contexts/UserContext';
import { profile as profileApi } from 'api';
import { convertMlsAssociationsToOptionProps } from 'lib/convert';

const GrStyledPaper = styled(Paper)<PaperProps>(
  ({ theme }) => muiAutocompleteStyles
);

type MlsAutoCompleteProps = {
  autoFocus?: boolean;
  company?: string;
  placeholder?: string;
  label?: string;
  onSelect: (option?: MLSAssociation) => void;
  optionsList: OptionProps[];
  mlsAssociationId: string;
  disabled?: boolean;
};

const MlsAutoComplete = (props: MlsAutoCompleteProps) => {
  const { mlsAssociations, setMlaAssociations } = useCurrentUser();
  const {
    autoFocus = false,
    onSelect,
    placeholder = 'Search',
    label = '',
    optionsList,
    mlsAssociationId,
    disabled = false,
  } = props;

  const [options, setOptions] = useState<OptionProps[]>(optionsList);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      const hasNoMlsAssociations = () =>
        !mlsAssociations || mlsAssociations.length === 0;
      if (hasNoMlsAssociations()) {
        const getMlsAssociations =
          async (): Promise<Array<MLSAssociation> | void> => {
            try {
              const { associations } = await profileApi.getMlsAssociations();
              if (associations?.length) {
                setMlaAssociations(associations);
              }
            } catch (error) {
              console.log(error);
            }
          };

        getMlsAssociations();
      } else {
        setOptions(convertMlsAssociationsToOptionProps(mlsAssociations));
      }
    }
    return () => {
      isMounted = false;
    };
  }, [mlsAssociations, setMlaAssociations]);

  const GrStyledPopper = useCallback((props) => {
    return (
      <Popper
        {...{
          ...props,
          ...{
            popperOptions: {
              modifiers: [
                {
                  name: 'offset',
                  options: { offset: [0, 10] },
                },
              ],
            },
          },
        }}
      />
    );
  }, []);

  const GrStyledTextField = styled(TextField)<TextFieldProps>(({ theme }) => ({
    ...muiInputOutlinedStyles,
    '& .MuiInputBase-input::placeholder': {
      color: '#25282A',
      opacity: 1,
      fontSmoothing: 'antialiased',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
    },
  }));

  const GrTextField = useCallback(
    (props: TextFieldProps) => {
      const fieldProps = { ...props };
      fieldProps.InputLabelProps = {
        ...fieldProps.InputLabelProps,
      };
      if (label !== '') {
        fieldProps.InputProps = {
          ...fieldProps.InputProps,
          ...{ disableUnderline: true },
        };
      }
      return (
        <GrStyledTextField
          color="secondary"
          variant="filled"
          fullWidth
          {...fieldProps}
        />
      );
    },
    [label, GrStyledTextField]
  );

  const selectedOption: OptionProps | null = useMemo(() => {
    const optionValue = mlsAssociations.find(
      (mls) => mls.id === mlsAssociationId
    );
    if (!optionValue) {
      return null;
    }
    return { value: optionValue?.id, label: optionValue?.name };
  }, [mlsAssociations, mlsAssociationId]);

  return (
    <div data-testid="mls-search-combo-box">
      <Autocomplete
        id="mls-autocomplete"
        options={options}
        renderInput={(params) => (
          <div ref={params.InputProps.ref}>
            <GrTextField
              {...params}
              label={label}
              placeholder={placeholder}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      className={classNames('tw-w-5 tw-h-5 tw-ml-1', {
                        'tw-opacity-50': disabled,
                      })}
                    />
                  </InputAdornment>
                ),
                autoFocus: autoFocus || false,
              }}
              variant={label === '' ? 'outlined' : 'filled'}
            />
          </div>
        )}
        PaperComponent={GrStyledPaper}
        PopperComponent={GrStyledPopper}
        freeSolo={true}
        value={selectedOption}
        onChange={(_, value: any) => {
          onSelect(value);
        }}
        onBlur={() => {
          onSelect();
        }}
        disabled={disabled}
      />
    </div>
  );
};

export { MlsAutoComplete };
