import React, { useState } from 'react';
import { TextField, PhoneField } from 'Components';
import { ReactComponent as TrashIcon } from 'Assets/icons/trash-blue.svg';
import AddIcon from '@mui/icons-material/Add';
import { AgentUserTeamMember, confirmationDialogConfig } from 'types';
import { controlClasses } from 'styles/control';

interface TeamMemberTouchedState {
  [key: string]: boolean;
}
export function TeamMembersListSection(props: {
  formik: any;
  setConfirmationConfig: (config: confirmationDialogConfig) => void;
  deleteWithoutConfirmation: (index: number) => void;
  disabled?: boolean;
}) {
  const [teamMemberTouched, setTeamMemberTouched] =
    useState<TeamMemberTouchedState>({});
  const {
    formik,
    setConfirmationConfig,
    deleteWithoutConfirmation,
    disabled = false,
  } = props;
  let teamMemberCounter = 1;
  const updatedTeamMembers = formik.values.teamMembers?.map(
    (value: AgentUserTeamMember) => {
      if (!value.hasOwnProperty('visible')) {
        value.visible = true;
      }
      if (value.visible) {
        value.visibleIndex = teamMemberCounter++;
      }
      return value;
    }
  );

  const handleAddTeamMember = () => {
    if (disabled) {
      return;
    }
    const newIndex = formik.values.teamMembers.length;

    // Check for empty required license fields
    const hasEmptyLicense = formik.values.license.some((license: any) => {
      return license.visible && (!license.state || !license.content);
    });

    // If there are empty licenses, mark them as touched
    if (hasEmptyLicense) {
      formik.values.license.forEach((license: any, index: number) => {
        if (license.visible && (!license.state || !license.content)) {
          formik.setFieldTouched(`license[${index}].state`, true);
          formik.setFieldTouched(`license[${index}].content`, true);
        }
      });
    }

    // Add new team member
    formik.setFieldValue('teamMembers', [
      ...formik.values.teamMembers,
      {
        firstName: '',
        lastName: '',
        title: '',
        email: '',
        phone: '',
        visible: true,
      },
    ]);

    // Mark new team member fields as touched
    formik.setFieldTouched(`teamMembers[${newIndex}].firstName`, true);
    formik.setFieldTouched(`teamMembers[${newIndex}].lastName`, true);
    formik.setFieldTouched(`teamMembers[${newIndex}].email`, true);

    // Update local touched state
    setTeamMemberTouched((prev) => ({
      ...prev,
      [`TeamMember${newIndex}`]: true,
    }));

    // Trigger validation
    formik.validateForm();
  };

  const deleteTeamMember = (index: number, visibleIndex: number) => {
    if (
      formik.touched[`firstName` + index] ||
      formik.touched[`lastName` + index] ||
      formik.touched[`title` + index] ||
      formik.touched[`email` + index] ||
      formik.touched[`phone` + index] ||
      formik.values.teamMembers[index].firstName ||
      formik.values.teamMembers[index].lastName ||
      formik.values.teamMembers[index].title ||
      formik.values.teamMembers[index].email ||
      formik.values.teamMembers[index].phone
    ) {
      setConfirmationConfig({
        index: index,
        show: true,
        text: `Are you sure you want to delete team member ${visibleIndex}`,
        subText:
          'This will remove the team member from your account once you click the save button.',
        type: 'TeamMember',
        isDelete: true,
      });
    } else {
      deleteWithoutConfirmation(index);
    }
  };
  const findIsError = (erroObj: any, key: string) => {
    return erroObj && erroObj[key];
  };
  const isTouched = (index: number): boolean => {
    return !!teamMemberTouched[`TeamMember${index}`];
  };
  const styles = controlClasses(disabled, 'tw-mb-4');
  return (
    <div>
      {updatedTeamMembers.length > 0 &&
        updatedTeamMembers.map((member: AgentUserTeamMember, index: number) =>
          member.visible ? (
            <div key={index}>
              <span className="text-gray-ravenswood tw-font-bold tw-text-md">
                Team member {member.visibleIndex}
              </span>
              <div className="tw-py-3 tw-grid tw-grid-cols-2 tw-gap-6">
                <TextField
                  id={`firstName${index}`}
                  name={`firstName${index}`}
                  label="First name"
                  required
                  inputProps={{
                    'data-testid': `dt-profile-teamFirstName${index}`,
                  }}
                  value={formik.values.teamMembers[index].firstName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      `teamMembers[${index}].firstName`,
                      event.target.value
                    );
                    setTeamMemberTouched((prev) => {
                      return { ...prev, [`TeamMember${index}`]: true };
                    });
                  }}
                  error={
                    !!findIsError(
                      formik.errors.teamMembers?.[index],
                      'firstName'
                    ) && !!isTouched(index)
                  }
                  helperText={
                    findIsError(
                      formik.errors.teamMembers?.[index],
                      'firstName'
                    ) && isTouched(index)
                      ? `${findIsError(
                          formik.errors.teamMembers?.[index],
                          'firstName'
                        )}`
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
                <TextField
                  id={`lastName${index}`}
                  name={`lastName${index}`}
                  label="Last name"
                  required
                  inputProps={{
                    'data-testid': `dt-profile-teamLastName${index}`,
                  }}
                  value={formik.values.teamMembers[index].lastName}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      `teamMembers[${index}].lastName`,
                      event.target.value
                    );
                    setTeamMemberTouched((prev) => {
                      return { ...prev, [`TeamMember${index}`]: true };
                    });
                  }}
                  error={
                    !!findIsError(
                      formik.errors.teamMembers?.[index],
                      'lastName'
                    ) && !!isTouched(index)
                  }
                  helperText={
                    findIsError(
                      formik.errors.teamMembers?.[index],
                      'lastName'
                    ) && isTouched(index)
                      ? `${findIsError(
                          formik.errors.teamMembers?.[index],
                          'lastName'
                        )}`
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
              </div>
              <div className="tw-py-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
                <TextField
                  id={`title${index}`}
                  name={`title${index}`}
                  label="Title"
                  inputProps={{
                    'data-testid': `dt-team-Title${index}`,
                  }}
                  value={formik.values.teamMembers[index].title}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      `teamMembers[${index}].title`,
                      event.target.value
                    );
                    setTeamMemberTouched((prev) => {
                      return { ...prev, [`TeamMember${index}`]: true };
                    });
                  }}
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
                <TextField
                  id={`email${index}`}
                  name={`email${index}`}
                  label="Email"
                  required
                  inputProps={{
                    'data-testid': `dt-team-professionalEmail${index}`,
                  }}
                  value={formik.values.teamMembers[index].email}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      `teamMembers[${index}].email`,
                      event.target.value
                    );
                    setTeamMemberTouched((prev) => {
                      return { ...prev, [`TeamMember${index}`]: true };
                    });
                  }}
                  error={
                    !!findIsError(
                      formik.errors.teamMembers?.[index],
                      'email'
                    ) && !!isTouched(index)
                  }
                  helperText={
                    findIsError(formik.errors.teamMembers?.[index], 'email') &&
                    isTouched(index)
                      ? `${findIsError(
                          formik.errors.teamMembers?.[index],
                          'email'
                        )}`
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
              </div>
              <div className="tw-py-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
                <PhoneField
                  id={`phone${index}`}
                  name={`phone${index}`}
                  label="Phone"
                  inputProps={{
                    'data-testid': `dt-team-Phone${index}`,
                  }}
                  value={formik.values.teamMembers[index].phone}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    formik.setFieldValue(
                      `teamMembers[${index}].phone`,
                      event.target.value
                    );
                  }}
                  placeholder="(XXX) XXX-XXXX"
                  error={
                    !!findIsError(
                      formik.errors.teamMembers?.[index],
                      'phone'
                    ) && !!isTouched(index)
                  }
                  helperText={
                    findIsError(formik.errors.teamMembers?.[index], 'phone') &&
                    isTouched(index)
                      ? `${findIsError(
                          formik.errors.teamMembers?.[index],
                          'phone'
                        )}`
                      : ''
                  }
                  onBlur={formik.handleBlur}
                  disabled={disabled}
                />
              </div>
              <button
                className={styles.link}
                onClick={() => {
                  if (!disabled) {
                    deleteTeamMember(index, member?.visibleIndex || 0);
                  }
                }}
              >
                <TrashIcon className={styles.icon} />
                <p className="tw-m-0 tw-font-bold">Delete team member</p>
              </button>
            </div>
          ) : null
        )}
      <div className={styles.link}>
        <button
          onClick={handleAddTeamMember}
          className="tw-flex tw-items-center"
        >
          <AddIcon className={styles.icon} />{' '}
          <p className="tw-m-0 tw-font-bold">Add team member</p>
        </button>
      </div>
    </div>
  );
}
