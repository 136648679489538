import { FC } from 'react';
import classNames from 'classnames';
import { Header1 } from 'Components/Header1';

type HeroProps = {
  title: string;
  subtitle?: string;
  alt: string;
  img1x: string;
  img2x: string;
  isHome?: boolean;
  qrCode?: string;
  playStore?: string;
  appStore?: string;
  imageWidth?: string;
};

const Hero: FC<HeroProps> = (props) => {
  const storeURL = process.env?.REACT_APP_STORE_URL;
  const handleClick = () => {
    window.open(storeURL, '_blank');
  };
  const heroHeaderClasses = [
    'tw-mt-0',
    'tw-mb-4',
    'md:tw-text-5xl',
    'md:tw-leading-[45px]',
    'desktop:md:tw-leading-14',
    'md:tw-font-bold',
    'fullhd:tw-leading-14',
  ];

  const mobileHeaderClasses = [
    'tw-mt-0',
    'tw-mb-4',
    '!tw-text-[32px]',
    '!tw-leading-9',
    '!tw-font-bold',
  ];

  return (
    <div className="tw-flex tw-justify-center [font-family:var(--font-family-headline,_'Proxima_Nova')]">
      <div
        className={classNames(
          'fullhd:tw-max-w-1392px',
          'tw-w-full',
          'tw-relative',
          'tw-max-w-1170px',
          'md:tw-min-h-68',
          'tw-@container',
          'tw-mx-0',
          'md:tw-mx-4',
          ...[props.imageWidth ? props.imageWidth : 'fullhd:tw-max-w-1392px']
        )}
      >
        <picture className={'tw-object-cover tw-object-center'}>
          <source srcSet={props.img1x} media="(max-width: 768px)" />
          <img srcSet={props.img2x} alt={props.alt} />
        </picture>
        <div>
          <div
            className="tw-bg-white
              tw-hidden
              md:tw-block
              md:tw-w-2/4
              tw-absolute 
              tw--left-0 
              tw--top-0 
              fullhd:tw-mt-[5.85rem] 
              fullhd:tw-ml-[7.5rem] 
              tw-mt-4 tw-ml-4
              tw-max-w-[34.5rem]
              tw-rounded
              tw-p-8"
          >
            <div>
              <Header1 classes={heroHeaderClasses}>{props.title}</Header1>
              <p className="tw-font-normal tw-m-0">{props.subtitle}</p>
            </div>
          </div>
          <img
            srcSet={props.qrCode}
            alt={props.alt}
            className="tw-mt-8 
            tw-h-[8.8rem] 
            tw-rounded
            tw-hidden 
            md:tw-block 
            tw-absolute 
            tw--bottom-0 
            tw--right-0
            fullhd:tw--left-0 
            fullhd:tw-mb-[5.85rem] 
            fullhd:tw-ml-[7.5rem] 
            tw-mb-4 
            tw-mr-4
            tw-p-6
            tw-bg-white"
          />
        </div>

        <div
          className="tw-bg-white 
            md:tw-hidden 
            tw-block 
            tw-mx-4 
            tw-p-6 
            tw-rounded
            tw-absolute 
            tw--mt-64
            tw-shadow-[0_3px_12px_1px_rgba(37,40,42,0.15)]"
        >
          <div>
            <Header1 classes={mobileHeaderClasses}>{props.title}</Header1>
            <p className="tw-font-normal tw-m-0">{props.subtitle}</p>
            <div className="tw-flex tw-pt-6 tw-gap-6 tw-justify-evenly">
              <div>
                <img
                  srcSet={props.appStore}
                  alt={props.alt}
                  className="tw-w-full tw-h-[44px] tw-cursor-pointer"
                  onClick={handleClick}
                />
              </div>
              <div>
                <img
                  srcSet={props.playStore}
                  alt={props.alt}
                  className="tw-w-full tw-h-[44px] tw-cursor-pointer"
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { Hero };
