import { ReactNode } from 'react';
import classNames from 'classnames';

const Header1 = (props: { children: ReactNode; classes?: string[] }) => (
  <h1
    className={classNames(
      'tw-font-bold',
      'tw-text-3xl',
      'md:tw-text-5xl',
      'tw-text-gray-ravenswood',
      'tw-tracking-normal',
      ...(props.classes ?? [])
    )}
  >
    {props.children}
  </h1>
);

export { Header1 };
