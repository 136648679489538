import classNames from 'classnames';
import { IconButton } from '@mui/material';
import { ReactComponent as CloseIcon } from 'Assets/icons/close.svg';
type CloseButtonProps = {
  onClick: ((e: any) => void) | undefined;
  disabled?: boolean;
  ariaLabel?: string;
  dataTestId?: string;
  className?: string;
  iconStyle?: string;
};
const CloseButton = (props: CloseButtonProps) => {
  const {
    onClick,
    disabled = false,
    ariaLabel = 'close',
    dataTestId = '',
    className = '',
    iconStyle = '',
  } = props;
  return (
    <IconButton
      aria-label={ariaLabel}
      onClick={onClick}
      disableRipple
      disableTouchRipple
      data-testid={dataTestId}
      disabled={disabled}
      className={className}
    >
      <CloseIcon className={classNames('!tw-w-5 !tw-h-5', iconStyle)} />
    </IconButton>
  );
};

export { CloseButton };
