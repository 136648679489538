import classNames from 'classnames';

const controlClasses = (disabled: boolean, linkClassname?: string) => ({
  link: classNames(
    'tw-font-bold tw-flex tw-items-center tw-w-fit',
    {
      'tw-cursor-pointer': !disabled,
      'tw-text-blue-link-darker': !disabled,
      'tw-text-gray-inactive': disabled,
    },
    linkClassname
  ),
  icon: classNames('tw-text-base tw-font-bold', {
    'tw-text-blue-link-darker': !disabled,
    'tw-text-gray-inactive': disabled,
    'tw-saturate-0': disabled,
    'tw-brightness-90': disabled,
  }),
});

export { controlClasses };
