import {
  Alert,
  Carousel,
  Header3,
  LoaderSmall,
  LoanSummaryTile,
  ReferButton,
  Container,
} from 'Components';
import useCurrentUser from 'contexts/UserContext';
import { filterOldFundedLoans, loanIsFundedOrInProgress } from 'lib/loans';
import { LoanType } from 'types';

const NoActiveDeals = () => (
  <Container
    containerPaddingAll={false}
    largeScreenWidth=""
    className="tw-mx-4 tw-h-52 md:tw-mx-0 lg:tw-h-auto lg:tw-py-6 tw-bg-white tw-flex tw-items-center tw-flex-row tw-justify-center tw-border tw-border-gray-neutral"
  >
    <div className="tw-flex tw-items-center tw-flex-col tw-justify-center tw-gap-4 tw-text-center tw-px-6 lg:tw-px-0">
      <Header3>You have no active closings at this time.</Header3>
      <ReferButton size="large" />
    </div>
  </Container>
);

const DealsInProgress = () => {
  const { loans, loansError, loansLoading } = useCurrentUser();
  const filteredLoans = filterOldFundedLoans(loans).filter((loan) =>
    loanIsFundedOrInProgress(loan)
  );
  const visibleCardCount = 8;
  // const visibleCardCount = 4;
  const dealsInProgressLoans =
    Array.isArray(filteredLoans) && filteredLoans.length
      ? filteredLoans.slice(0, visibleCardCount)
      : [];

  const getGridColClasses = (loanCount: number) =>
    loanCount === 1 ? 'md:tw-grid-cols-1' : 'md:tw-grid-cols-2';

  const flatCards = (
    <div
      className={`sm:tw-pl-4 md:tw-pl-0 tw-flex tw-flex-row md:tw-grid ${getGridColClasses(
        dealsInProgressLoans.length
      )} md:tw-w-full tw-overflow-x-auto tw-overflow-y-hidden md:tw-h-auto tw-gap-4 md:tw-gap-6`}
    >
      {dealsInProgressLoans.map((loan, index) => (
        <LoanSummaryTile
          loan={loan}
          key={`${loan.tempId}-${index}`}
          className={`${
            dealsInProgressLoans.length === 1
              ? 'tw-basis-[94%]'
              : 'tw-basis-[312px] tw-min-w-[312px]'
          } tw-shrink-0 tw-overflow-hidden`}
          isSendMessageToShellApp={true}
          totalLoans={dealsInProgressLoans.length}
          mode="extraNarrow"
          verticalGap={true}
        />
      ))}
      <div className="tw-w-0">&nbsp;</div>
    </div>
  );

  const carouselCards: React.ReactElement[] = [];
  if (dealsInProgressLoans.length > 4) {
    const loanSets: LoanType[][] = [
      dealsInProgressLoans.slice(0, 4),
      dealsInProgressLoans.slice(4),
    ];
    loanSets.forEach((loanSet) => {
      carouselCards.push(
        <div className={`tw-grid tw-grid-cols-2 tw-w-full tw-gap-6`}>
          {loanSet.map((loan, index) => (
            <LoanSummaryTile
              loan={loan}
              key={`${loan.tempId}-${index}`}
              className="tw-min-h-[246px]"
              isSendMessageToShellApp={true}
              mode="extraNarrow"
            />
          ))}
        </div>
      );
    });
  }

  return (
    <div className="md:tw-px-0 tw-overflow-x-auto md:tw-overflow-x-hidden">
      {loansError ? (
        <Alert
          show={true}
          severity="error"
          showClasses={['tw-px-4', 'tw-max-w-xs']}
          message="Loans could not be loaded"
          type="inline"
        />
      ) : loansLoading ? (
        <div className="tw-px-4">
          <LoaderSmall />
        </div>
      ) : dealsInProgressLoans.length > 0 ? (
        <>
          {dealsInProgressLoans.length > 4 ? (
            <>
              <div className="tw-hidden md:tw-block">
                <Carousel
                  cards={carouselCards}
                  slug="loans"
                  heightClass="tw-h-[550px]"
                />
              </div>
              <div className="tw-flex md:tw-hidden">{flatCards}</div>
            </>
          ) : (
            flatCards
          )}
        </>
      ) : (
        <NoActiveDeals />
      )}
    </div>
  );
};

export { DealsInProgress };
