import { ReactNode } from 'react';
import classNames from 'classnames';

/*
 * The "Shadowed Container" used to contain most of the
 * interactive content across the site. Login, Sign-up, Referrals
 * all used this container in the original design.
 */

export type ContainerProps = {
  containerPaddingAll?: Boolean;
  containerPaddingTop?: Boolean;
  containerShadow?: Boolean;
  containerSize?: string;
  containerHeight?: number;
  largeScreenWidth?: string;
  mobileScreenWidth?: string;
  className?: string;
  children?: ReactNode;
  dataTestId?: string;
  containerStyle?: object;
};

export function Container(props: ContainerProps) {
  const containerPaddingAll =
    props.containerPaddingAll === undefined ||
    props.containerPaddingAll === true
      ? 'tw-p-5'
      : '';

  const containerPaddingTop =
    props.containerPaddingTop !== undefined &&
    props.containerPaddingTop === true
      ? 'tw-pt-5'
      : '';

  const containerShadow = !!props.containerShadow
    ? 'tw-shadow-container'
    : 'md:tw-border md:tw-border-gray-neutral';

  const twContainerWidth =
    props.largeScreenWidth ??
    (props.containerSize === undefined || props.containerSize !== 'medium'
      ? '114'
      : '84');
  const largeScreenWidth = 'lg:tw-w-' + twContainerWidth;
  const mobileScreenWidth = !!props.mobileScreenWidth
    ? 'tw-w-' + props.mobileScreenWidth
    : '';

  const containerHeight =
    props.containerHeight !== undefined
      ? 'lg:tw-h-' + props.containerHeight
      : '';

  const className = props.className === undefined ? '' : props.className;
  const divAttributes: {
    className: string;
    'data-testid'?: string;
    style?: object;
  } = {
    className: classNames(
      'tw-rounded',
      containerPaddingAll,
      containerPaddingTop,
      containerShadow,
      largeScreenWidth,
      mobileScreenWidth,
      containerHeight,
      className
    ),
  };
  if (props.containerStyle) {
    divAttributes['style'] = props.containerStyle;
  }
  if (props?.dataTestId) {
    divAttributes['data-testid'] = props.dataTestId;
  }
  return <div {...divAttributes}>{props.children}</div>;
}
