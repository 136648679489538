const muiThemeSettings = {
  palette: {
    primary: {
      main: '#C5DFE8',
    },
    secondary: {
      main: '#2175DA',
    },
  },
  typography: {
    fontFamily: "'proxima-nova', 'Roboto', 'Helvetica', 'Arial', san-serif",
    fontSize: 14,
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        listbox: {
          border: '1px solid #2175DA',
          borderRadius: 4,
          '&li': {
            '&:hover': {
              backgroundColor: '#EEF4FC',
            },
            '&:active': {
              backgroundColor: '#EEF4FC',
            },
          },
        },
        option: {
          paddingTop: '14px !important',
          paddingBottom: '14px !important',
          '& .Mui-focused': {
            backgroundColor: '#EEF4FC',
          },
          '&:hover': {
            backgroundColor: '#EEF4FC',
          },
          '&:active': {
            backgroundColor: '#EEF4FC',
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: '2px',
          marginTop: '0px',
          color: '#C12950',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          padding: 0,
          backgroundColor: '#EEF4FC',
          '&:autofill': {
            backgroundColor: '#EEF4FC',
          },
          '&:-webkit-autofill': {
            backgroundColor: '#EEF4FC',
          },
          '&:hover': {
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'transparent',
            },
          },
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#EEF4FC',
          },
          '&:active': {
            backgroundColor: '#EEF4FC',
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          padding: '10px 14px',
          backgroundColor: '#EEF4FC',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          border: '1px solid #2175DA',
          borderRadius: 4,
        },
        list: {
          borderRadius: 4,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#EEF4FC',
          },
          '&:active': {
            backgroundColor: '#EEF4FC',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        filled: {
          '&:focus': {
            backgroundColor: 'transparent',
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#EEF4FC',
          },
          '&:active': {
            transform: 'scale(.9)',
          },
        },
      },
    },
  },
};

const muiInputFilledStyles = {
  '& .MuiFilledInput-root': {
    border: '1px solid #DEEAF9',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#EEF4FC',
    '&:hover': {
      backgroundColor: '#EEF4FC',
      border: '1px solid #2175DA',
    },
    '& .Mui-focused': {
      backgroundColor: '#EEF4FC',
      border: '1px solid #2175DA',
    },
    '& .Mui-error': {
      '& label': {
        color: '#847f82',
      },
    },
  },
  '& .MuiTextField-root': {
    backgroundColor: '#EEF4FC',
  },
  '& .MuiInputLabel-root': {
    color: '#25282A',
    fontSize: '0.95rem',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#25282A',
  },
  '& .MuiFilledInput-root.Mui-focused': {
    backgroundColor: '#EEF4FC',
    borderColor: '#2175DA',
  },
  '& .Mui-error.MuiInputLabel-root': {
    color: '#25282A',
  },
  '& .Mui-error.MuiFilledInput-root': {
    backgroundColor: '#f7e9ed',
    borderColor: '#EFC9D3',
    '&:focused': {
      borderColor: '#de8d9f',
    },
  },
  '& .Mui-error.MuiFilledInput-root.Mui-focused': {
    backgroundColor: '#f7e9ed !important',
    borderColor: '#EFC9D3',
    '&:focused': {
      borderColor: '#de8d9f',
    },
  },
  '& .Mui-error.MuiFilledInput-root:hover': {
    borderColor: '#C12950',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: '1px solid #C5DFE8',
  },
  '& .Mui-error.MuiFormLabel-asterisk': {
    borderColor: '#C12950',
  },
  '& .MuiFilledInput-input:disabled': {
    backgroundColor: '#F3F3F3',
    borderColor: '#B1B1B1',
  },
  '& .MuiFilledInput-root.Mui-disabled': {
    backgroundColor: '#F3F3F3',
    borderColor: '#B1B1B1',
  },
};

const muiInputOutlinedStyles = {
  '& .MuiOutlinedInput-root': {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#EEF4FC',
    border: '1px solid #DEEAF9',
    '&:hover': {
      backgroundColor: '#EEF4FC',
      borderColor: '#DEEAF9',
      border: '1px solid #2175DA',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#DEEAF9',
      },
    },
    '& .Mui-focused': {
      backgroundColor: '#EEF4FC',
      borderColor: '#DEEAF9',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: '#DEEAF9',
      },
    },
    '& .Mui-error': {
      '& label': {
        color: '#847f82',
      },
    },
  },
  '& .MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent !important',
  },
  '& .MuiTextField-root': {
    backgroundColor: '#EEF4FC',
  },
  '& .MuiInputLabel-root': {
    color: '#25282A',
    fontSize: '0.95rem',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#25282A',
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    backgroundColor: '#EEF4FC',
    border: '1px solid #2175DA',
  },
  '& .Mui-error.MuiInputLabel-root': {
    color: '#25282A',
  },
  '& .Mui-error.MuiOutlinedInput-root': {
    backgroundColor: '#f7e9ed',
    borderColor: '#EFC9D3',
    '&:focused': {
      borderColor: '#de8d9f',
    },
  },
  '& .Mui-error.MuiOutlinedInput-root.Mui-focused': {
    backgroundColor: '#f7e9ed !important',
    borderColor: '#EFC9D3',
    '&:focused': {
      borderColor: '#de8d9f',
    },
  },
  '& .Mui-error.MuiOutlinedInput-root:hover': {
    borderColor: '#C12950',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: 'none',
  },
  '& .MuiOutlinedInput-input:disabled': {
    backgroundColor: '#F3F3F3',
    borderColor: '#B1B1B1',
  },
  '& .MuiOutlinedInput-root.Mui-disabled': {
    backgroundColor: '#F3F3F3',
    borderColor: '#B1B1B1',
  },
};

const muiSelectStyles = {
  ...muiInputFilledStyles,
  ...{
    '& li:hover': {
      backgroundColor: '#EEF4FC',
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: '#EEF4FC',
      borderColor: '#2175DA',
    },
    '& .MuiSelect-icon': {
      top: 'calc(50% - 0.8em)',
    },
    '& .MuiFilledInput-root.Mui-disabled': {
      backgroundColor: '#F3F3F3',
      borderColor: '#B1B1B1',
    },
    '& .MuiSelect-iconFilled': {
      height: '20px',
      width: '20px',
    },
    '& .MuiSelect-iconFilled.Mui-disabled': {
      filter: 'opacity(50%)',
    },
    '& .MuiSelect-filled.Mui-disabled': {
      borderColor: '#B1B1B1',
    },
  },
};

const muiAutocompleteStyles = {
  '& .MuiOutlinedInput-input ': {
    paddingLeft: 0,
    color: '#25282A',
  },
  '& .MuiOutlinedInput-root': {
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#EEF4FC',
    border: '1px solid #DEEAF9',
    '&:hover': {
      backgroundColor: '#EEF4FC',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #2175DA',
      },
    },
    '& .Mui-focused': {
      backgroundColor: '#EEF4FC',
      '& .MuiOutlinedInput-notchedOutline': {
        border: '1px solid #2175DA',
      },
    },
    '& .Mui-error': {
      '& label': {
        color: '#847f82',
      },
    },
  },
  '& .MuiOutlinedInput-root:hover.MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent !important',
  },
  '& .MuiTextField-root': {
    backgroundColor: '#EEF4FC',
  },
  '& .MuiInputLabel-root': {
    color: '#25282A',
    fontSize: '0.95rem',
  },
  '& .MuiInputLabel-root.Mui-focused': {
    color: '#25282A',
    borderColor: 'transparent !important',
  },
  '& .MuiOutlinedInput-root.Mui-focused': {
    backgroundColor: '#EEF4FC',
    borderColor: '#2175DA',
  },
  '& .Mui-error.MuiInputLabel-root': {
    color: '#25282A',
  },
  '& .Mui-error.MuiOutlinedInput-root': {
    backgroundColor: '#f7e9ed',
    borderColor: '#EFC9D3',
    '&:focused': {
      borderColor: '#de8d9f',
    },
  },
  '& .Mui-error.MuiOutlinedInput-root.Mui-focused': {
    backgroundColor: '#f7e9ed !important',
    borderColor: 'transparent !important',
    '&:focused': {
      borderColor: 'transparent !important',
    },
  },
  '& .Mui-error.MuiOutlinedInput-root:hover': {
    borderColor: 'transparent !important',
  },
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: 'transparent !important',
  },
};

export {
  muiAutocompleteStyles,
  muiInputFilledStyles,
  muiInputOutlinedStyles,
  muiSelectStyles,
  muiThemeSettings,
};
