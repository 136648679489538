import { ReactNode } from 'react';
import classNames from 'classnames';
import { lazily } from 'react-lazily';

import { BackButton, Header1 } from 'Components';

import useAppContext from 'contexts/AppContext';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';
import type { NodeOrString } from 'types';

const { Footer } = lazily(() => import('Components/Footer'));
const { LoginHeader } = lazily(() => import('Components/LoginHeader'));

type LoggedInPageProps = {
  title?: string;
  sectionClassNames?: string[];
  header1ClassNames?: string[];
  addHorizontalMarginInMobile?: boolean;
  replaceHeaderWithBackButtonInShellApp?: boolean;
  addBackButton?: boolean;
  children: NodeOrString;
  footerChildren?: ReactNode;
  isSectionClassNames?: boolean;
};

const LoggedInPage = (props: LoggedInPageProps) => {
  const {
    children,
    footerChildren,
    title,
    sectionClassNames = [],
    header1ClassNames = [],
    addHorizontalMarginInMobile = true,
    replaceHeaderWithBackButtonInShellApp = false,
    addBackButton = false,
    isSectionClassNames = true,
  } = props;

  const { width } = useWindowDimensions();
  const { isRunningInShellApp, mobileNavIsOpen } = useAppContext();

  if (isSectionClassNames) {
    if (addHorizontalMarginInMobile) {
      sectionClassNames.push('tw-px-4');
      sectionClassNames.push('md:tw-mx-0');
    }
    sectionClassNames.push(
      ...[
        'md:tw-px-4',
        'md:tw-mb-0',
        'md:tw-p-5',
        'lg:tw-pt-4',
        'tw-w-full',
        'lg:tw-w-913px',
        'lg:tw-px-0',
        'lg:tw-mx-auto',
        'tw-flex',
        'tw-flex-col',
      ]
    );
  }

  let showBackButton =
    addBackButton ||
    (replaceHeaderWithBackButtonInShellApp && isRunningInShellApp);

  const backButtonClasses = [
    'md:tw-w-full',
    'md:tw-flex',
    'tw-flex-col',
    'tw-items-center',
    'tw-px-4',
    'md:tw-px-8',
  ];
  if (isRunningInShellApp && !replaceHeaderWithBackButtonInShellApp) {
    backButtonClasses.push('tw-hidden');
  } else {
    backButtonClasses.push('tw-flex');
  }
  const containerClasses: string[] = [];
  if (isRunningInShellApp) {
    containerClasses.push('tw-pt-4');
  }

  return (
    <div className={classNames(containerClasses)}>
      {!(replaceHeaderWithBackButtonInShellApp && isRunningInShellApp) && (
        <LoginHeader />
      )}
      {showBackButton && (
        <div
          className={classNames(backButtonClasses)}
          data-testid="page-footer"
        >
          <nav className="tw-block tw-w-full tw-mt-0 tw-mb-4 fullhd:tw-max-w-1440px">
            <BackButton />
          </nav>
        </div>
      )}
      {(width >= 768 || !mobileNavIsOpen) && (
        <>
          <div className="tw-flex tw-bg-white">
            <section className={classNames(sectionClassNames)}>
              {title && (
                <Header1 classes={['tw-mt-0', ...header1ClassNames]}>
                  {title}
                </Header1>
              )}
              {children}
            </section>
          </div>
          {!isRunningInShellApp && <Footer>{footerChildren}</Footer>}
        </>
      )}
    </div>
  );
};

export { LoggedInPage };
