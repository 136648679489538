import { Alert } from 'Components/Alert';
import { AlertProps } from 'Components/Alert/Alert';
import { isReferralConsentNotDenied, loanIsInProgress } from 'lib/loans';
import { useMemo } from 'react';
import { LoanType } from 'types';
import {
  CONSENT_DENIED_MESSAGE,
  CONSENT_REQUESTED_MESSAGE,
  CONSENT_REFERRAL_MESSAGE_LONG,
  CONSENT_REFERRAL_MESSAGE_SHORT,
} from './borrower-consent-alert.constants';
import CircleTickIcon from 'Assets/icons/check-tick-circle.svg';

interface BorrowerConsentAlertProps
  extends Omit<AlertProps, 'message' | 'show'> {
  loan: LoanType | null | undefined;
  mode?: 'narrow' | 'extraNarrow' | 'wide';
}

/*
  if the loan is in progress and consent is not given,
  displays an appropriate warning regarding borrower consent
  (either consent requested or explicitly denied)
*/
export const BorrowerConsentAlert = (props: BorrowerConsentAlertProps) => {
  const { loan, mode = 'narrow', ...alertProps } = props;
  const consentAlertMessage: string | null = useMemo(() => {
    if (!loan) {
      return null;
    }
    const isLoanInProgress = loanIsInProgress(loan);
    if (!isLoanInProgress) {
      return null;
    }

    const { borrower } = loan;

    const consent = loan.consent?.borrower;

    if (isReferralConsentNotDenied(loan)) {
      const template =
        mode === 'narrow' || mode === 'extraNarrow'
          ? CONSENT_REFERRAL_MESSAGE_SHORT
          : CONSENT_REFERRAL_MESSAGE_LONG;
      return template.replace('{{homebuyer}}', borrower?.firstName || '');
    }

    if (consent === 1) {
      return null;
    } else if (consent === 0) {
      return CONSENT_DENIED_MESSAGE;
    } else {
      return CONSENT_REQUESTED_MESSAGE;
    }
  }, [loan, mode]);

  const customShowClasses = ['!tw-bg-[#EEF8F2]', '!tw-border-[#54BA83]'];

  return consentAlertMessage ? (
    <Alert
      {...alertProps}
      show
      message={consentAlertMessage}
      showClasses={customShowClasses}
      type="inline"
      severity="success"
      customIcon={CircleTickIcon}
      messageClassName="tw-text-base tw-font-normal tw-leading-5"
    />
  ) : null;
};
