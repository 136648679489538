import { find, has, get, map } from 'lodash';
import {
  AgentUserStats,
  LoanMilestone,
  LoanMilestoneStage,
  LoanStatusDates,
  AbridgedLoanOfficer,
  LoanApiType,
  LoanType,
  NodeOrString,
  MilestoneIdType,
} from 'types';
import { capitalCase, includesCaseInsensitive } from 'lib/string';
import {
  diffInDays,
  formatDatetime,
  getLatest,
  parseDateFromDateTime,
} from 'lib/date';
import { average } from 'lib/math';

/*
  Buckets and their stages
  1. Referral
    - referral date
  2. Pre-approval
    - date of pre-approval
    - application date (either via DM or LO)
  3. Contract
    - address, city, state, zip
  4. Underwriting
    - UnderwritingSubmittedDate
    - appraisal date
    - underwriting approved
    - appraisal complete
    - cleared to close
  5. Closing
    - date closing scheduled and closing process
    - final closing
    - funded

  Total of 12 steps
*/

const getAndFormatLoanStatusDate = (
  loan: LoanType,
  field: keyof LoanStatusDates
): string => {
  const value = get(loan, ['loanStatusDates', field], '');
  return value ? formatDatetime(value, true) : '';
};

const dayInMs = 24 * 60 * 60 * 1000;
const fundedLoanCutoffInDays = 30;
const fundedLoanCutoffInMs = fundedLoanCutoffInDays * dayInMs;

const filterOldFundedLoans = (loans: LoanType[]): LoanType[] => {
  const loansAltered = map(loans, (loan) => {
    const loanObj: any = { ...loan };
    loanObj.fundedBeforeCutoff = false;
    const fundedDateString = get(loan, 'loanStatusDates.funded');
    if (fundedDateString) {
      const fundedDate = new Date(fundedDateString);
      if (fundedDate) {
        const cutoffTimestamp = new Date().getTime() - fundedLoanCutoffInMs;
        loanObj.fundedBeforeCutoff = fundedDate.getTime() < cutoffTimestamp;
      }
    }
    return loanObj;
  });
  const filteredLoans: LoanType[] = loansAltered.filter(
    (loan) => !loan.fundedBeforeCutoff
  );
  return filteredLoans.map((loan: any) => {
    delete loan.fundedBeforeCutoff;
    return loan as LoanType;
  });
};

const formatStageSingleDate = (
  loan: LoanType,
  fields: string[],
  template: string,
  emptyTemplate: string
): NodeOrString => {
  const dateValue =
    fields[0] && has(loan, fields[0]) ? get(loan, fields[0]) : '';
  const formattedDateValue =
    dateValue && typeof dateValue === 'string'
      ? formatDatetime(dateValue, true)
      : '';
  const populatedTemplate = template.replace(
    '{{formattedField}}',
    formattedDateValue
  );
  return emptyTemplate !== ''
    ? !!dateValue
      ? populatedTemplate
      : emptyTemplate
    : populatedTemplate;
};
const formatPreApprovalFormatter = (
  loan: LoanType,
  fields: string[],
  template: string,
  emptyTemplate: string
): NodeOrString => {
  const formattedDateValue = getAndFormatLoanStatusDate(
    loan,
    'applicationComplete'
  );
  const fileStarter = get(loan, 'otherLoanInfo.fileStarter') || '';
  let message = template.replace('{{formattedField}}', formattedDateValue);
  if (fileStarter !== '') {
    const allCapsFileStarter = fileStarter.trim().toUpperCase();
    if (['ONELOAN SDK', 'GR3 SDK'].includes(allCapsFileStarter)) {
      message = `Client applied via Digital Mortgage ${formattedDateValue}`;
    } else {
      message = `Client applied with ${fileStarter} ${formattedDateValue}`;
    }
  }
  return message;
};
const formatContractMilestone = (
  loan: LoanType,
  fields: string[],
  template: string,
  emptyTemplate: string
): NodeOrString => {
  const address = get(loan, 'property.address', '');
  const city = get(loan, 'property.city', '');
  const state = get(loan, 'property.state', '');
  const zip = get(loan, 'property.zip', '');
  const underContract = address && !includesCaseInsensitive(address, 'PREQUAL');
  const formattedAddress = `${capitalCase(address)}, ${capitalCase(
    city
  )}, ${state} ${zip}`;
  const populatedTemplate = template.replace(
    '{{formattedField}}',
    formattedAddress
  );
  return underContract ? populatedTemplate : emptyTemplate;
};
const formatAppraisalOrPiw = (
  loan: LoanType,
  fields: string[],
  template: string,
  emptyTemplate: string,
  hasPIW?: boolean
): NodeOrString => {
  if (hasPIW) {
    return 'The property qualifies for an inspection waiver';
  }
  return `We've ordered the appraisal ${getAndFormatLoanStatusDate(
    loan,
    'appraisalOrdered'
  )}`;
};
const formatClosingMilestoneFunded = (
  loan: LoanType,
  fields: string[],
  template: string,
  emptyTemplate: string
): NodeOrString => {
  const formattedClosedDate = getAndFormatLoanStatusDate(
    loan,
    'closed'
  )?.trim();
  const formattedFundedDate = getAndFormatLoanStatusDate(
    loan,
    'funded'
  )?.trim();
  return `Congratulations the remaining closing documents were signed${
    formattedClosedDate ? ' ' + formattedClosedDate : ''
  }. The loan has funded / will fund on ${formattedFundedDate}`;
};
const closingMilestoneClosingScheduledFormatter = (
  loan: LoanType,
  fields: string[],
  template: string,
  emptyTemplate: string
): NodeOrString => {
  const formattedDate = getAndFormatLoanStatusDate(loan, 'scheduledCloseDate');
  const isEclosing = get(loan, 'otherLoanInfo.isEclosing', '');
  if (isEclosing) {
    if (isEclosing.match(/eNote/i)) {
      return `The closing is scheduled for ${formattedDate} and the client has selected fully electronic closing`;
    } else if (isEclosing.match(/ineligible/i)) {
      return `The closing is scheduled for ${formattedDate}`;
    } else if (isEclosing.match(/Hybrid Closing/i)) {
      return `The closing is scheduled for ${formattedDate} and the client has selected a hybrid closing process`;
    }
  }
  return `The closing is scheduled for ${formattedDate}`;
};
const referralCustomCompletionTest = (loan: LoanApiType, fields: string[]) =>
  true;

const preapprovalApplicationComplete = (
  loan: LoanApiType,
  fields: string[]
) => {
  const dateValue = has(loan, 'loanStatusDates.applicationComplete')
    ? get(loan, 'loanStatusDates.applicationComplete')
    : '';
  const fileStarter = has(loan, 'otherLoanInfo.fileStarter')
    ? get(loan, 'otherLoanInfo.fileStarter')
    : '';
  return !!(dateValue || fileStarter);
};
const contractCustomCompletionTest = (loan: LoanApiType, fields: string[]) => {
  const address = get(loan, 'property.address', '');
  return address && !includesCaseInsensitive(address, 'PREQUAL');
};
const underwritingAppraisalOrPiwCustomCompletionTest = (
  loan: LoanApiType,
  fields: string[],
  hasPIW?: boolean
) => {
  if (hasPIW) {
    return true;
  }
  const appraisalOrdered = get(loan, 'loanStatusDates.appraisalOrdered', '');
  return !!appraisalOrdered;
};
const closingScheduledCustomCompletionTest = (loan: LoanApiType) =>
  !!get(loan, 'loanStatusDates.scheduledCloseDate', '');
const closingFundedCustomCompletionTest = (loan: LoanApiType) =>
  !!get(loan, 'loanStatusDates.funded', '');

const referralMilestone: LoanMilestone = {
  id: 'referral',
  name: 'Referral',
  stages: [
    {
      id: 'referral',
      fields: ['loanStatusDates.referral'],
      template: 'Referred via Rate Agents {{formattedField}}',
      emptyTemplate: 'Client was referred outside of Rate Agents',
      formatterFinder: 'formatStageSingleDate',
      customCompletionTestFinder: 'referralCustomCompletionTest',
      shortTemplate: 'Referral',
    },
  ],
};
const preapprovalMilestone: LoanMilestone = {
  id: 'preApproval',
  name: 'Pre-approval',
  stages: [
    {
      id: 'pre-approved',
      fields: ['loanStatusDates.preApproval'],
      template: 'Client pre-approved {{formattedField}}',
      emptyTemplate: 'Client pre-approved (date to be added once pre-approved)',
      formatterFinder: 'formatStageSingleDate',
      shortTemplate: 'Pre-approved',
    },
    {
      id: 'application-complete',
      fields: [
        'loanStatusDates.applicationComplete',
        'otherLoanInfo.fileStarter',
      ],
      template:
        'Client applied via Digital Mortgage (or) with LO {{formattedField}}',
      formatterFinder: 'formatPreApprovalFormatter',
      customCompletionTestFinder: 'preapprovalApplicationComplete',
      shortTemplate: 'Client applied',
    },
  ],
};
const contractMilestone: LoanMilestone = {
  id: 'contract',
  name: 'Contract',
  stages: [
    {
      id: 'contract',
      fields: [
        'property.address',
        'property.city',
        'property.state',
        'property.zip',
      ],
      template:
        'Congratulations! Your client is under contract at {{formattedField}}',
      emptyTemplate: 'Pending sales contract',
      formatterFinder: 'formatContractMilestone',
      customCompletionTestFinder: 'contractCustomCompletionTest',
      shortTemplate: 'Under contract',
    },
  ],
};
const underwritingMilestone: LoanMilestone = {
  id: 'underwriting',
  name: 'Underwriting',
  stages: [
    {
      id: 'submitted-to-uw',
      fields: ['loanStatusDates.submittedToUnderwriting'],
      template: `Your client's loan is with the UW for conditional approval as of {{formattedField}}`,
      formatterFinder: 'formatStageSingleDate',
      shortTemplate: 'In underwriting',
    },
    {
      id: 'appraisal-or-piw',
      fields: ['loanStatusDates.appraisalOrdered'],
      template: '',
      formatterFinder: 'formatAppraisalOrPiw',
      customCompletionTestFinder:
        'underwritingAppraisalOrPiwCustomCompletionTest',
      shortTemplate: 'Underwriting',
    },
    {
      id: 'conditional-approval',
      fields: ['loanStatusDates.conditionalApproval'],
      template: `You client's loan has been conditionally approved {{formattedField}}`,
      formatterFinder: 'formatStageSingleDate',
      shortTemplate: 'Conditionally approved',
    },
    {
      id: 'appraisal-complete',
      fields: ['loanStatusDates.appraisalReceived'],
      template: `The appraisal is complete {{formattedField}}`,
      formatterFinder: 'formatStageSingleDate',
      includeStageTest: (hasPIW?: boolean) => !hasPIW,
      shortTemplate: 'Appraisal completed',
    },
    {
      id: 'cleared-to-close',
      fields: ['loanStatusDates.finalApprovalCtc'],
      template: `Loan is cleared to close {{formattedField}}`,
      formatterFinder: 'formatStageSingleDate',
      shortTemplate: 'Clear to close',
    },
  ],
};
const closingMilestone: LoanMilestone = {
  id: 'closing',
  name: 'Closing',
  stages: [
    {
      id: 'closing-scheduled',
      fields: [
        'loanStatusDates.scheduledCloseDate',
        'otherLoanInfo.isEclosing',
      ],
      template: '',
      formatterFinder: 'closingMilestoneClosingScheduledFormatter',
      customCompletionTestFinder: 'closingScheduledCustomCompletionTest',
      shortTemplate: 'Closing scheduled',
    },
    {
      id: 'closing-title',
      fields: ['loanStatusDates.closingPackageTitle'],
      template: `Final closing details are being worked through with the title company {{formattedField}}`,
      formatterFinder: 'formatStageSingleDate',
      shortTemplate: 'Final details with title',
    },
    {
      id: 'funded',
      fields: ['loanStatusDates.closed', 'loanStatusDates.funded'],
      template: '',
      formatterFinder: 'formatClosingMilestoneFunded',
      customCompletionTestFinder: 'closingFundedCustomCompletionTest',
      shortTemplate: 'Closed',
    },
  ],
};

const milestoneTypes: LoanMilestone[] = [
  referralMilestone,
  preapprovalMilestone,
  contractMilestone,
  underwritingMilestone,
  closingMilestone,
];

const getMilestone = (milestoneId?: MilestoneIdType) =>
  find(milestoneTypes, (milestone) => milestone.id === milestoneId);

const getMilestoneName = (milestoneId?: MilestoneIdType) => {
  const milestone = getMilestone(milestoneId);
  return milestone ? milestone.name : '';
};

const getLoanMilestone = (loan: LoanType, milestoneId: MilestoneIdType) =>
  find(loan?.status?.milestones, (milestone) => milestone.id === milestoneId);

const getStagebyId = (
  loan: LoanType,
  stageId: string
): LoanMilestoneStage | undefined => {
  if (loan?.status && loan.status?.milestones) {
    for (let milestone of loan.status?.milestones) {
      for (let stage of milestone.stages) {
        if (stage.id === stageId) {
          return stage;
        }
      }
    }
  }
  return undefined;
};

const customCompletionTestRegistry: { [key: string]: Function } = {
  referralCustomCompletionTest,
  preapprovalApplicationComplete,
  contractCustomCompletionTest,
  underwritingAppraisalOrPiwCustomCompletionTest,
  closingScheduledCustomCompletionTest,
  closingFundedCustomCompletionTest,
};

const getCustomCompletionTestFromString = (
  completionTestFinder: string
): Function => {
  if (completionTestFinder) {
    return customCompletionTestRegistry[completionTestFinder] || (() => {});
  }
  return () => {};
};

const formatterRegistry: { [key: string]: Function } = {
  formatStageSingleDate,
  formatPreApprovalFormatter,
  formatClosingMilestoneFunded,
  closingMilestoneClosingScheduledFormatter,
  formatAppraisalOrPiw,
  formatContractMilestone,
};

const getFunctionFromFormatterString = (formatterFinder: string): Function => {
  if (formatterFinder) {
    return formatterRegistry[formatterFinder] || (() => {});
  }
  return () => {};
};
const getFormattedStage = (loan: LoanType, stageId: string): NodeOrString => {
  const stage = getStagebyId(loan, stageId);
  if (stage) {
    const { fields, template, emptyTemplate, formatterFinder } = stage;

    if (
      typeof formatterFinder === 'string' &&
      !!template &&
      template.match('{{formattedField}}')
    ) {
      const formatterFunction = getFunctionFromFormatterString(formatterFinder);
      return formatterFunction(
        loan,
        fields,
        template || '',
        emptyTemplate || '',
        loan?.hasPIW
      );
    }
  }
  return '';
};

const getFormattedShortStage = (
  loan: LoanType,
  stageId: string
): NodeOrString => {
  const stage = getStagebyId(loan, stageId);
  if (stage) {
    const { fields, emptyTemplate, formatterFinder, shortTemplate } = stage;
    if (
      typeof formatterFinder === 'string' &&
      !!shortTemplate &&
      shortTemplate.match('{{formattedField}}')
    ) {
      const formatterFunction = getFunctionFromFormatterString(formatterFinder);
      return formatterFunction(
        loan,
        fields,
        shortTemplate || '',
        emptyTemplate || '',
        loan?.hasPIW
      );
    } else if (!!shortTemplate) {
      return shortTemplate;
    }
  }
  return '';
};

const sortLoanDateDescending = (loans: LoanType[]) =>
  map(loans, (loan) => loan as LoanType).sort(
    (a, b) => b.sortTime - a.sortTime
  );

const processStage = (
  stage: LoanMilestoneStage,
  loan: LoanApiType,
  hasPIW: boolean
): LoanMilestoneStage | boolean => {
  let includeStage = true;
  if (
    has(stage, 'includeStageTest') &&
    typeof stage.includeStageTest === 'function'
  ) {
    includeStage = stage.includeStageTest(hasPIW);
  }
  if (includeStage) {
    const processedStage: LoanMilestoneStage = { ...stage };
    if (
      has(stage, 'customCompletionTestFinder') &&
      typeof stage.customCompletionTestFinder === 'string'
    ) {
      const formatterFunction = getCustomCompletionTestFromString(
        stage.customCompletionTestFinder
      );
      processedStage.completed = formatterFunction(loan, stage.fields, hasPIW);
    } else {
      let completedFieldCount = 0;
      for (const field of stage.fields) {
        if (has(loan, field) && get(loan, field)) {
          completedFieldCount++;
        }
      }
      processedStage.completed = completedFieldCount === stage.fields.length;
    }
    return processedStage;
  }
  return false;
};

const processLoanMilestone = (
  milestoneType: LoanMilestone,
  loan: LoanApiType,
  hasPIW: boolean,
  previousMilestoneWasCompleted: boolean
) => {
  const processedStages: LoanMilestoneStage[] = [];
  if (Array.isArray(milestoneType?.stages) && milestoneType.stages.length) {
    for (const stage of milestoneType.stages) {
      const processedStageResult = processStage(stage, loan, hasPIW);
      if (
        processedStageResult !== false &&
        typeof processedStageResult !== 'boolean' &&
        processedStageResult.id !== undefined
      ) {
        processedStages.push(processedStageResult);
      }
    }
  }
  let completedStageCount = 0;
  for (const stage of processedStages) {
    if (stage.completed) {
      completedStageCount++;
    }
  }
  return {
    id: milestoneType.id,
    name: milestoneType.name,
    stages: processedStages,
    started: completedStageCount > 0,
    completed: completedStageCount === processedStages.length,
  } as LoanMilestone;
};

const loanMilestones = (
  loan: LoanApiType,
  hasPIW: boolean
): LoanMilestone[] => {
  const milestones: LoanMilestone[] = [];
  let previousMilestoneWasCompleted = true;
  for (const milestoneType of milestoneTypes) {
    const processedMilestone = processLoanMilestone(
      milestoneType,
      loan,
      hasPIW,
      previousMilestoneWasCompleted
    );
    previousMilestoneWasCompleted = !!processedMilestone.completed;
    milestones.push(processedMilestone);
  }
  return milestones as LoanMilestone[];
};

const hasPropertyInspectionWaiver = (loan: LoanApiType): boolean => {
  const piwChecked = get(loan, 'otherLoanInfo.piwChecked', false);
  const appraisalOrdered = get(loan, 'loanStatusDates.appraisalOrdered', '');
  return !!piwChecked && !appraisalOrdered;
};

const addSortDateAndStatusToLoan = (apiLoan: LoanApiType): LoanType => {
  const hasPIW = hasPropertyInspectionWaiver(apiLoan);
  const milestones = loanMilestones(apiLoan, hasPIW);

  let stagesCount = 0,
    completedStageCount = 0;
  let lastCompletedMilestone: MilestoneIdType = 'referral',
    lastCompletedStage = '';
  let currentMilestone: MilestoneIdType = 'referral';

  for (const milestone of milestones) {
    if (milestone?.completed) {
      lastCompletedMilestone = milestone.id;
    }
    if (milestone.started) {
      currentMilestone = milestone.id;
    }
    if (Array.isArray(milestone?.stages) && milestone.stages.length) {
      stagesCount += milestone.stages.length;
      for (const stage of milestone.stages) {
        completedStageCount += stage?.completed ? 1 : 0;
        if (stage?.completed) {
          lastCompletedStage = stage.id;
        }
      }
    }
  }

  const sortDate = getLatest(apiLoan.loanStatusDates || {}, true);
  const loan: LoanType = {
    ...apiLoan,
    sortTime: sortDate ? new Date(sortDate).getTime() : 0,
    status: {
      milestones: [...milestones],
      completedCount: completedStageCount,
      totalCount: stagesCount,
      currentMilestone,
      lastCompletedMilestone,
      lastCompletedStage,
    },
    hasPIW,
  };
  return loan;
};

const addSortDateAndStatusToLoans = (loans: LoanApiType[]): LoanType[] =>
  map(loans, (loan) => addSortDateAndStatusToLoan(loan));

const loanIsClosed = (loan: LoanApiType) =>
  !!get(loan, 'loanStatusDates.closed');
const loanIsFunded = (loan: LoanApiType) =>
  !!get(loan, 'loanStatusDates.funded');
const loanIsReferral = (loan: LoanApiType) =>
  !!get(loan, 'loanStatusDates.referral');

const uniqAndActiveLoanOfficersFromLoans = (
  loans: LoanApiType[]
): AbridgedLoanOfficer[] => {
  const los = [] as AbridgedLoanOfficer[];
  loans.forEach((loan) => {
    if (
      loan?.team?.loanOfficer &&
      loan.team?.loanOfficer?.id &&
      find(
        los,
        (lo) => lo.loanOfficerId === parseInt(`${loan.team?.loanOfficer.id}`)
      ) === undefined
    ) {
      los.push({
        loanOfficerId: parseInt(loan.team?.loanOfficer.id),
        displayName: loan.team?.loanOfficer?.name || '',
        city: '',
        state: '',
        company: loan.company,
        email: loan.team?.loanOfficer?.email || '',
        nmlsId: loan.team?.loanOfficer?.nmlsId || '',
        employeeActive: !!loan.team?.loanOfficer?.employeeActive,
        photoUrl: loan.team?.loanOfficer?.photoUrl || '',
        title: loan.team?.loanOfficer?.title || '',
      });
    }
  });
  return los.filter((lo) => lo.employeeActive);
};

const filterRollingYearFundedLoans = (
  loans: LoanApiType[] | LoanApiType[]
): LoanApiType[] | LoanApiType[] => {
  return loans.filter((loan) => {
    const fundedDateString = get(loan, 'loanStatusDates.funded');
    if (!!fundedDateString) {
      const dayDiff = diffInDays(fundedDateString);
      return dayDiff >= 0 && dayDiff <= 365;
    }
    return false;
  });
};

const filterCurrentCalendarMonthFundedLoans = (
  loans: LoanType[] | LoanApiType[]
): LoanType[] | LoanApiType[] => {
  const nowDate = new Date();
  const currentMonth = nowDate.getMonth() + 1;
  const currentYear = nowDate.getFullYear();
  return loans.filter((loan) => {
    const fundedDateString = get(loan, 'loanStatusDates.funded');
    if (!!fundedDateString) {
      const parsedFundedDate = parseDateFromDateTime(fundedDateString);
      if (parsedFundedDate.year) {
        return (
          parsedFundedDate.year === currentYear &&
          parsedFundedDate.month === currentMonth
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
};

const filterCurrentCalendarMonthLoansInProgress = (
  loans: LoanType[] | LoanApiType[]
): LoanType[] | LoanApiType[] => {
  const nowDate = new Date();
  const currentMonth = nowDate.getMonth() + 1;
  const currentYear = nowDate.getFullYear();
  return loans.filter((loan) => {
    const fundedDateString = get(loan, 'loanStatusDates.funded');
    const estimatedClosingDateString = get(
      loan,
      'loanStatusDates.loanScheduledClosing'
    );
    const respaDate = get(loan, 'loanStatusDates.respa');
    if (!fundedDateString && !!estimatedClosingDateString && !!respaDate) {
      const parsedEstClosingDate = parseDateFromDateTime(
        estimatedClosingDateString
      );
      if (parsedEstClosingDate.year) {
        return (
          parsedEstClosingDate.year === currentYear &&
          parsedEstClosingDate.month === currentMonth
        );
      } else {
        return false;
      }
    } else {
      return false;
    }
  });
};

const getPurchasePrice = (loan: LoanApiType): number => {
  const purchasePriceAmount = get(loan, 'loanDetails.purchasePriceAmount');
  return purchasePriceAmount && typeof purchasePriceAmount === 'string'
    ? parseInt(purchasePriceAmount)
    : (purchasePriceAmount as number);
};

const agentStatsFromLoans = (loans: LoanApiType[]): AgentUserStats => {
  const stats: AgentUserStats = {
    rollingYear: {
      averageCtc: null,
    },
    monthToDate: {
      funded: {
        loansCount: 0,
        salesVolume: 0,
      },
      projectedClosed: {
        loansCount: 0,
        salesVolume: 0,
      },
    },
  };
  const ctcs: number[] = [];
  filterRollingYearFundedLoans(loans).forEach((loan) => {
    const respaDateString = get(loan, 'loanStatusDates.respa');
    const ctcDateString = get(loan, 'loanStatusDates.finalApprovalCtc');
    if (!!respaDateString && !!ctcDateString) {
      const daysToClose = diffInDays(respaDateString, ctcDateString);
      ctcs.push(daysToClose);
    }
  });
  if (ctcs.length > 0) {
    stats.rollingYear.averageCtc = Math.round(average(ctcs) * 10) / 10;
  }

  filterCurrentCalendarMonthFundedLoans(loans).forEach((loan) => {
    if (loanIsFunded(loan)) {
      stats.monthToDate.funded.loansCount++;
      const purchasePrice = getPurchasePrice(loan);
      if (purchasePrice && purchasePrice > 0) {
        stats.monthToDate.funded.salesVolume += purchasePrice;
      }
    }
  });

  filterCurrentCalendarMonthLoansInProgress(loans).forEach((loan) => {
    stats.monthToDate.projectedClosed.loansCount++;
    const purchasePrice = getPurchasePrice(loan);
    if (purchasePrice && purchasePrice > 0) {
      stats.monthToDate.projectedClosed.salesVolume += purchasePrice;
    }
  });
  return stats;
};

const inactiveLoanFolders: string[] = [
  '(Archive)',
  '(Trash)',
  '2ArchiveServer',
  'Adverse 1',
  'Adverse 2',
  'Adverse 3',
  'Adverse 4',
  'Adverse 5',
  'Adverse 6',
  'Adverse Loans',
  'Closed Loans',
  'ER - Archive',
  'GRA - PRE-TEST',
  'GriOnline-Testing',
  'GRA Online - Testing',
  'GRA Online-Testing',
  'Imported Archive',
  'Samples',
  'testFolder',
  'TESTING PD',
  'Testing PD',
  'To Archive',
];

const loanIsActive = (loanFolder: string) =>
  !inactiveLoanFolders.includes(loanFolder);

const loanIsFundedOrInactive = (loan: LoanApiType) =>
  loanIsFunded(loan) || !loanIsActive(loan?.otherLoanInfo?.loanFolder || '');

const loanIsInProgress = (loan: LoanApiType) =>
  !loanIsFunded(loan) && loanIsActive(loan?.otherLoanInfo?.loanFolder || '');

const loanIsFundedOrInProgress = (loan: LoanApiType) =>
  loanIsFunded(loan) || loanIsActive(loan?.otherLoanInfo?.loanFolder || '');

const lastCompletedMilestoneIs = (
  milestoneId: MilestoneIdType,
  loan: LoanType
): boolean => {
  const { status } = loan;
  if (status) {
    return status.lastCompletedMilestone === milestoneId;
  }
  return false;
};

const milestoneIsUntouched = (
  milestoneId: MilestoneIdType,
  loan: LoanType
): boolean => {
  const milestone = getLoanMilestone(loan, milestoneId);
  if (milestone) {
    let stagesCompleted = 0;
    for (const stage of milestone.stages) {
      if (stage.completed) {
        stagesCompleted++;
      }
    }
    return stagesCompleted === 0;
  }
  return true;
};

const formatBorrowerDisplayName = (
  fullName: string,
  firstName: string,
  lastName: string
): string => fullName || `${firstName} ${lastName}`.trim();

type ReferralDispositionStatus = {
  status: string;
  description: string;
};

const formatReferralDisposition = (
  status: string,
  datetime: string
): string | undefined => {
  const statusMap: ReferralDispositionStatus[] = [
    { status: 'Left voicemail', description: 'Left voicemail' },
    { status: 'Pending docs', description: 'Pending borrower docs' },
    { status: 'No response', description: 'No response' },
    { status: 'Does not qualify', description: 'Does not qualify' },
    { status: 'Pre-approved', description: 'Pre-approved' },
    { status: 'Offer out', description: 'Offer out' },
    { status: 'Lost to competitor', description: 'Lost to competitor' },
  ];
  const matches = statusMap.filter((item) => item.status === status);
  const description =
    matches && matches[0] ? matches[0].description : status || 'Unknown';
  return `${description} ${formatDatetime(datetime, true)}`;
};

const isReferralConsentNotDenied = (loan: LoanType) =>
  loan?.status?.currentMilestone === 'referral' && loan.consent?.borrower !== 0;

export {
  addSortDateAndStatusToLoan,
  addSortDateAndStatusToLoans,
  agentStatsFromLoans,
  filterCurrentCalendarMonthFundedLoans,
  filterCurrentCalendarMonthLoansInProgress,
  filterOldFundedLoans,
  filterRollingYearFundedLoans,
  formatBorrowerDisplayName,
  formatReferralDisposition,
  getMilestoneName,
  getFormattedStage,
  getFormattedShortStage,
  formatStageSingleDate,
  isReferralConsentNotDenied,
  lastCompletedMilestoneIs,
  loanIsActive,
  loanIsClosed,
  loanIsFunded,
  loanIsFundedOrInactive,
  loanIsFundedOrInProgress,
  loanIsInProgress,
  loanIsReferral,
  milestoneIsUntouched,
  getFunctionFromFormatterString,
  milestoneTypes,
  sortLoanDateDescending,
  uniqAndActiveLoanOfficersFromLoans,
};
