import classNames from 'classnames';

import useAppContext from 'contexts/AppContext';
import { formatPhoneNumber } from 'lib/string';
import useWindowDimensions from 'lib/hooks/useWindowDimensions';

import messageIcon from 'Assets/icons/mail.svg';
import phoneIcon from 'Assets/icons/phone.svg';

export interface ContactLineProps {
  email?: string | null;
  phone?: string | null;
  mode?: 'narrow' | 'extraNarrow' | 'wide';
}

const ContactLine = ({ email, phone, mode = 'narrow' }: ContactLineProps) => {
  const { tenant } = useAppContext();
  const { colors } = tenant;
  const { isMobileScreenWidth, width } = useWindowDimensions();

  const tertiary = [`tw-text-${colors.primary.body.base}`, 'tw-font-normal'];

  const calcMode = width < 415 ? 'extraNarrow' : mode;

  const layout: 'oneLine' | 'twoLines' =
    email &&
    (calcMode === 'extraNarrow' ||
      (email.length > 50 && mode === 'narrow') ||
      (email.length > 80 && mode === 'wide'))
      ? 'twoLines'
      : 'oneLine';

  const className = classNames(tertiary);

  return (
    <div
      className={classNames('tw-flex', {
        'tw-flex-row tw-gap-3': layout === 'oneLine',
        'tw-flex-col': layout === 'twoLines',
      })}
    >
      {email && (
        <div
          className={classNames('tw-flex tw-items-start', {
            'tw-leading-5': layout === 'twoLines',
            'tw-text-sm':
              mode === 'extraNarrow' ||
              (layout === 'twoLines' && mode === 'narrow'),
          })}
        >
          <img
            className="tw-w-4 tw-mr-1 tw-mt-1"
            src={messageIcon}
            alt="email"
          />
          <div
            className={classNames(className, {
              'tw-truncate': !isMobileScreenWidth,
              'tw-break-all': isMobileScreenWidth,
            })}
          >
            {email}
          </div>
        </div>
      )}
      {phone && (
        <div className="tw-flex tw-items-center">
          <img className="tw-w-4 tw-mr-1" src={phoneIcon} alt="phone" />
          <div
            className={classNames(className, {
              'tw-leading-5 tw-text-sm': mode === 'extraNarrow',
            })}
          >
            {formatPhoneNumber(phone)}
          </div>
        </div>
      )}
    </div>
  );
};

export { ContactLine };
