import useMediaQuery from '@mui/material/useMediaQuery';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import { styled, useTheme } from '@mui/material/styles';

import { CloseButton } from 'Components';

const GrDialog = styled(Dialog)<DialogProps>(({ theme }) => ({
  '& .MuiDialog-paper': {
    [theme.breakpoints.up('md')]: {
      boxShadow: 'none',
      borderRadius: 4,
      verticalAlign: 'top',
      marginTop: 85,
    },
  },
  '& .MuiBackdrop-root': {
    backdropFilter: 'blur(4px)',
    backgroundColor: 'rgba(0,0,0,0.15)',
  },
}));

const Modal = (props: DialogProps) => {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <GrDialog {...props} scroll="body" fullScreen={fullScreen} maxWidth={false}>
      <div className="tw-flex tw-justify-end tw-pt-4 tw-pl-6 tw-pr-4">
        <CloseButton
          onClick={(e) => {
            if (typeof props.onClose === 'function') {
              props.onClose(e, 'escapeKeyDown');
            }
          }}
          dataTestId="dialog-close-icon-button"
        />
      </div>
      {props.children}
    </GrDialog>
  );
};

export { Modal as Dialog };
