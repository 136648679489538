import type {
  BorrowerShareWithAgentConsent,
  CompanyType,
  LoanMilestone,
  LoanApiType,
  DocStorageServiceDocument,
  OpenHouse,
  MilestoneIdType,
} from 'types';
import { createDayOfMonthLocal, daysAgoDate, daysFutureDate } from 'lib/date';
import {
  generateLoTestLo,
  generateLoDariusDelgado,
  generateLoExLoanofficer,
  generateLoShantBanosian,
  generateLoTomMaroulis,
  generateLoMattCibula,
  generateLoMatthewBanasiak,
  generateLoMattTierney,
  generateLoLorrainePiechnik,
  generateLoLiLi,
  generateLoJennyLewis,
  generateLoJenniferSchenk,
  generateMcTestMc,
  generateLcTestLc,
  generatePmTestPm,
  generateSaTestSa,
} from './test-personnel';

const firstDayOfMonth = createDayOfMonthLocal(1).toISOString();
const today = daysAgoDate(0).toISOString();
const yesterday = daysAgoDate(1).toISOString();
const days2ago = daysAgoDate(2).toISOString();
const days3ago = daysAgoDate(3).toISOString();
const days4ago = daysAgoDate(4).toISOString();
const days5ago = daysAgoDate(5).toISOString();
const days6ago = daysAgoDate(6).toISOString();
const days7ago = daysAgoDate(7).toISOString();
const days8ago = daysAgoDate(8).toISOString();
const days9ago = daysAgoDate(9).toISOString();
const days10ago = daysAgoDate(10).toISOString();
const days11ago = daysAgoDate(11).toISOString();
const days12ago = daysAgoDate(12).toISOString();
const days13ago = daysAgoDate(13).toISOString();
const days14ago = daysAgoDate(14).toISOString();
const days15ago = daysAgoDate(15).toISOString();
const days16ago = daysAgoDate(16).toISOString();
const days17ago = daysAgoDate(17).toISOString();
const days18ago = daysAgoDate(18).toISOString();
const days36ago = daysAgoDate(36).toISOString();
const days45ago = daysAgoDate(45).toISOString();
const days46ago = daysAgoDate(46).toISOString();
const days47ago = daysAgoDate(47).toISOString();
const days49ago = daysAgoDate(49).toISOString();

const days61ago = daysAgoDate(61).toISOString();
const days62ago = daysAgoDate(62).toISOString();
const days63ago = daysAgoDate(63).toISOString();
const days64ago = daysAgoDate(64).toISOString();
const days65ago = daysAgoDate(65).toISOString();
const days66ago = daysAgoDate(66).toISOString();
const days67ago = daysAgoDate(67).toISOString();
const days68ago = daysAgoDate(68).toISOString();
const days69ago = daysAgoDate(69).toISOString();
const days70ago = daysAgoDate(70).toISOString();
const days71ago = daysAgoDate(72).toISOString();
const days72ago = daysAgoDate(72).toISOString();

const days14future = daysFutureDate(14).toISOString();

const fundedLoan1: LoanApiType = {
  borrower: {
    email: 'myaccount-servicing31dev@yopmail.com',
    fullName: 'Ron Tintin',
    phone: '888-555-1544',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '9664 N STANFORD AVE',
    city: 'CLOVIS',
    state: 'CA',
    zip: '93619',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Funding',
    loanNumber: '224106424DEV',
    fastTrackEligibility: 'ineligible',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 240000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '2hkuh3of-2839h2h-23nl2-23532k3',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000001',
  },
  team: {
    loanOfficer: generateLoExLoanofficer(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI',
  loanStatusDates: {
    closed: '2022-06-01T00:00:00Z',
    scheduledCloseDate: '2022-06-01T00:00:00Z',
    disclosuresSigned: null,
    applicationComplete: null,
    applicationStarted: '2022-05-17T00:00:00Z',
    appraisalOrdered: null,
    rateLockExpDate: '2022-06-16T00:00:00Z',
    funded: '2022-09-27T00:00:00Z',
    submittedToUnderwriting: '2022-05-17T00:00:00Z',
    conditionalApproval: '2022-05-17T00:00:00Z',
    powerBidSubmitted: null,
    finalApprovalCtc: '2022-05-17T00:00:00Z',
    appraisalReceived: null,
  },
  consent: {
    borrower: 1,
  },
};

const loanMilestones: LoanMilestone[] = [
  {
    id: 'referral',
    name: 'Referral',
    stages: [
      {
        id: 'referral',
        fields: ['loanStatusDates.referral'],
        template: 'Referred via Rate Agents {{formattedField}}',
        emptyTemplate: 'Client was referred outside of Rate Agents',
      },
    ],
    completed: false,
  },
  {
    id: 'preApproval',
    name: 'Pre-approval',
    stages: [
      {
        id: 'pre-approved',
        fields: ['loanStatusDates.preApproval'],
        template: 'Client Pre-approved {{formattedField}}',
        emptyTemplate:
          'Client Pre-approved (date to be added once pre-approved)',
      },
      {
        id: 'application-complete',
        fields: ['loanStatusDates.applicationComplete'],
        template:
          'Client applied via Digital Mortgage (or) with LO {{formattedField}}',
      },
    ],
    completed: false,
  },
  {
    id: 'contract',
    name: 'Contract',
    stages: [
      {
        id: 'contract',
        fields: ['loanStatusDates.preApproval'],
        template: 'Client Pre-approved {{formattedField}}',
        emptyTemplate:
          'Client Pre-approved (date to be added once pre-approved)',
      },
    ],
    completed: false,
  },
  {
    id: 'underwriting',
    name: 'Underwriting',
    stages: [
      {
        id: 'submitted-to-uw',
        fields: ['loanStatusDates.submittedToUnderwriting'],
        template: `Your client's loan is with the UW for conditional approval as of {{formattedField}}`,
      },
      {
        id: 'appraisal-or-piw',
        fields: ['loanStatusDates.appraisalOrdered'],
        template: `We've ordered the appraisal {{formattedField}} / The property qualifies for an inspection waiver`,
      },
      {
        id: 'conditional-approval',
        fields: ['loanStatusDates.conditionalApproval'],
        template: `You client's loan has been conditionally approved {{formattedField}}`,
      },
      {
        id: 'appraisal-complete',
        fields: ['loanStatusDates.appraisalReceived'],
        template: `The appraisal is complete {{formattedField}}`,
      },
      {
        id: 'cleared-to-close',
        fields: ['loanStatusDates.finalApprovalCtc'],
        template: `Loan is cleared to close {{formattedField}}`,
      },
    ],
    completed: false,
  },
  {
    id: 'closing',
    name: 'Closing',
    stages: [
      {
        id: 'closing-scheduled',
        fields: [
          'loanStatusDates.scheduledCloseDate',
          'otherLoanInfo.isEclosing',
        ],
        template: `The Closing is scheduled for {{formattedField}} and the client has selected X (Wet/hybrid/full e) closing process`,
      },
      {
        id: 'closing-title',
        fields: ['loanStatusDates.closingPackageTitle'],
        template: `Final closing details are being worked through with the title company {{formattedField}}`,
      },
      {
        id: 'funded',
        fields: [
          'loanStatusDates.signedRemainingDocuments',
          'loanStatusDates.funded',
        ],
        template: `The loan has funded / will fund on {{formattedField}}`,
      },
    ],
    completed: false,
  },
];

const sampleLoanData: LoanApiType = {
  borrower: {
    email: 'myaccount-brokeredle2@yopmail.com',
    fullName: 'Ronies Tintin',
    phone: '777-777-7777',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '2 Test St',
    city: 'Blanchester',
    state: 'OH',
    zip: '45107',
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '39c28ce4-437e-465f-bcff-bef62173363f',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000002',
  },
  team: {
    loanOfficer: generateLoTestLo(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    closed: '2022-04-26T00:00:00Z',
    scheduledCloseDate: null,
    disclosuresSigned: null,
    applicationComplete: '2022-04-20T00:00:00Z',
    applicationStarted: '2022-04-20T00:00:00Z',
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: null,
    conditionalApproval: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  consent: {
    borrower: 1,
  },
};

const dmxLoan: LoanApiType = {
  borrower: {
    email: 'margaritasanchez2@yopmail.com',
    fullName: 'Margarita Sánchez',
    phone: '567-567-5678',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: 'PREQUAL',
    city: 'Medford',
    state: 'MA',
    zip: '02155',
  },
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    applicationComplete: '2023-04-21T00:00:00Z',
    preApproval: '2023-04-20T00:00:00Z',
    referral: '2023-04-10T00:00:00Z',
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: null,
    conditionalApproval: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'OneLoan SDK',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '39c28ce4-437e-465f-bcff-bef62173363f',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000003',
    purchasePriceAmount: 325000,
  },
  team: {
    loanOfficer: generateLoDariusDelgado(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  consent: {
    borrower: 1,
  },
};

const loanByLO: LoanApiType = {
  borrower: {
    email: 'arturoblanco@yopmail.com',
    fullName: 'Arturo Blanco',
    phone: '789-789-7890',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: 'PREQUAL',
    city: 'Demorest',
    state: 'GA',
    zip: '30535',
  },
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    applicationComplete: '2023-04-25T00:00:00Z',
    preApproval: '2023-04-15T00:00:00Z',
    referral: '2023-04-10T00:00:00Z',
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: null,
    conditionalApproval: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'Darius Delgado',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '39c28ce4-437e-465f-bcff-34j5li34jo3i4',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000004',
  },
  team: {
    loanOfficer: generateLoDariusDelgado(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  consent: {
    borrower: 1,
  },
};

const loanUnderContract: LoanApiType = {
  borrower: {
    email: 'leticiaiglesias@yopmail.com',
    fullName: 'Leticia Iglesias',
    phone: '234-234-2345',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '1220 6th St',
    city: 'Howard Lake',
    state: 'MN',
    zip: '55349',
  },
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    applicationComplete: '2023-03-26T00:00:00Z',
    preApproval: '2023-03-28T00:00:00Z',
    referral: '2023-03-18T00:00:00Z',
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: null,
    conditionalApproval: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'Darius Delgado',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: 'wefawef-437e-465f-wefjwoeif-jwoe87w8e',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000005',
  },
  team: {
    loanOfficer: generateLoDariusDelgado(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  consent: {
    borrower: 1,
  },
};

const loanUnderwriting: LoanApiType = {
  borrower: {
    email: 'alejandroserrano@yopmail.com',
    fullName: 'Alejandro Serrano',
    phone: '234-234-2345',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '1906 Road 20',
    city: 'Waco',
    state: 'NE',
    zip: '68460',
  },
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    applicationComplete: '2023-03-26T00:00:00Z',
    preApproval: '2023-03-28T00:00:00Z',
    referral: '2023-03-18T00:00:00Z',
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: '2023-04-03T00:00:00Z',
    conditionalApproval: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'Darius Delgado',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: 'y3j2ia-j3iwofj-j3j2o3i343-234232',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000006',
  },
  team: {
    loanOfficer: generateLoDariusDelgado(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  consent: {
    borrower: 1,
  },
};

const loanPiw: LoanApiType = {
  borrower: {
    email: 'guillermo@yopmail.com',
    fullName: 'Guillermo Suárez',
    phone: '234-234-2345',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '29 Pine Haven Rd',
    city: 'Tijeras',
    state: 'NM',
    zip: '87059',
  },
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    applicationComplete: '2023-03-26T00:00:00Z',
    preApproval: '2023-03-28T00:00:00Z',
    referral: '2023-03-18T00:00:00Z',
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: '2023-04-03T00:00:00Z',
    conditionalApproval: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'OneLoan SDK',
    piwChecked: true,
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: 'bjo23i5-234oi2j3o-j3j2o3i343-234232',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000007',
  },
  team: {
    loanOfficer: generateLoDariusDelgado(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  consent: {
    borrower: 1,
  },
};

const loanAppraisal: LoanApiType = {
  borrower: {
    email: 'jaimegomez@yopmail.com',
    fullName: 'Jaime Gómez',
    phone: '234-234-2345',
  },
  coborrower: {
    email: 'mariana@yopmail.com',
    fullName: 'Mariana Gómez ',
    phone: '234-234-2345',
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '29 Pine Haven Rd',
    city: 'Tijeras',
    state: 'NM',
    zip: '87059',
  },
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    applicationComplete: '2023-03-26T00:00:00Z',
    preApproval: '2023-03-28T00:00:00Z',
    referral: '2023-03-18T00:00:00Z',
    appraisalOrdered: '2023-04-04T00:00:00Z',
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: '2023-04-03T00:00:00Z',
    conditionalApproval: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'OneLoan SDK',
    piwChecked: false,
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: 'j2o3ij2o3ij2o3i4j2o3',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000008',
  },
  team: {
    loanOfficer: generateLoDariusDelgado(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  consent: {
    borrower: 1,
  },
};

const loanElectronicClose: LoanApiType = {
  borrower: {
    email: 'miguel@yopmail.com',
    fullName: 'Miguel Jiménez ',
    phone: '234-234-2345',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '306 S 3rd St',
    city: 'Okemah',
    state: 'OK',
    zip: '74859',
  },
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: '2023-04-08T00:00:00Z',
    disclosuresSigned: null,
    applicationComplete: '2023-03-26T00:00:00Z',
    preApproval: '2023-03-28T00:00:00Z',
    referral: '2023-03-18T00:00:00Z',
    appraisalOrdered: '2023-04-04T00:00:00Z',
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: '2023-04-03T00:00:00Z',
    conditionalApproval: '2023-04-05T00:00:00Z',
    appraisalReceived: '2023-04-06T00:00:00Z',
    finalApprovalCtc: '2023-04-07T00:00:00Z',
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'OneLoan SDK',
    piwChecked: false,
    isEclosing: 'eNote',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '2joi23-jo2aaef-23oijaoijef-23iorjawe',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000009',
  },
  team: {
    loanOfficer: generateLoDariusDelgado(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  consent: {
    borrower: 1,
  },
};

const loanHybridElectronicClose: LoanApiType = {
  borrower: {
    email: 'felipe@yopmail.com',
    fullName: 'Felipe Pérez ',
    phone: '234-234-2345',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'Dubtep1964@jourrapide.com',
      id: null,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '635 E Southeast Dr',
    city: 'Clearwater',
    state: 'KS',
    zip: '67026',
  },
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: '2023-04-08T00:00:00Z',
    disclosuresSigned: null,
    applicationComplete: '2023-03-26T00:00:00Z',
    preApproval: '2023-03-28T00:00:00Z',
    referral: '2023-03-18T00:00:00Z',
    appraisalOrdered: '2023-04-04T00:00:00Z',
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: '2023-04-03T00:00:00Z',
    conditionalApproval: '2023-04-05T00:00:00Z',
    appraisalReceived: '2023-04-06T00:00:00Z',
    finalApprovalCtc: '2023-04-07T00:00:00Z',
  },
  otherLoanInfo: {
    actionTaken: '',
    loanFolder: 'GRI Testing Online',
    currentMilestone: 'Started',
    loanNumber: '224096745DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'OneLoan SDK',
    piwChecked: false,
    isEclosing: 'Hybrid Closing',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 321000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: 'uo2i3jo-2ijo2-afjawi3',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000010',
  },
  team: {
    loanOfficer: generateLoDariusDelgado(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  consent: {
    borrower: 1,
  },
};

const testDocuments = (): DocStorageServiceDocument[] => {
  return [
    {
      documentId: 'a',
      fileName: 'Appraisal.pdf',
      created: daysAgoDate(10).toISOString(),
    },
    {
      documentId: 'b',
      fileName: 'Preapproval 1.pdf',
      created: daysAgoDate(5).toISOString(),
    },
    {
      documentId: 'c',
      fileName: 'Credit Report.pdf',
      created: daysAgoDate(7).toISOString(),
    },
    {
      documentId: 'd',
      fileName: 'Preapproval 2',
      created: daysAgoDate(6).toISOString(),
    },
    {
      documentId: 'e',
      fileName: 'Gift Funds Letter and Affirmation from Robert Downey Jr.pdf',
      created: daysAgoDate(7).toISOString(),
    },
    {
      documentId: 'f',
      fileName: null,
    },
  ];
};

// in progress loans
const inProgress1: LoanApiType = {
  borrower: {
    email: 'batbayart.tsakhia@yopmail.com',
    fullName: 'Batbayar Tsakhia',
    phone: '206-643-9763',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '9433 Marlboro Ave',
    city: 'Barnwell',
    state: 'SC',
    zip: '29812',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Active Test Folder',
    currentMilestone: 'Funding',
    loanNumber: '748968462DEV',
    fastTrackEligibility: 'ineligible',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 240000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '3870ad61-1a25-43fc-9c01-7d4cca4bebe4',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000011',
    purchasePriceAmount: 325000,
  },
  team: {
    loanOfficer: generateLoShantBanosian(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    applicationComplete: days3ago,
    applicationStarted: firstDayOfMonth,
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    loanScheduledClosing: null,
    respa: firstDayOfMonth,
    submittedToUnderwriting: null,
    conditionalApproval: null,
    powerBidSubmitted: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
};

const preApproval1: LoanApiType = {
  borrower: {
    email: 'anastacio.oliveira@yopmail.com',
    fullName: 'Anastacio Oliveira',
    phone: '206-643-9763',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: 'PREQUAL',
    city: 'Canton',
    state: 'GA',
    zip: '30114',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Active Test Folder',
    currentMilestone: 'Funding',
    loanNumber: '980121969604DEV',
    fastTrackEligibility: 'ineligible',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 460000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: 'b8689ee0-99e8-4531-bbf7-bd239ce71155',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000012',
    purchasePriceAmount: 575000,
  },
  team: {
    loanOfficer: generateLoTomMaroulis(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    referral: days49ago,
    preApproval: days47ago,
    applicationComplete: null,
    applicationStarted: days46ago,
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    loanScheduledClosing: null,
    respa: days45ago,
    submittedToUnderwriting: null,
    conditionalApproval: null,
    powerBidSubmitted: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
};

const preApproval2: LoanApiType = {
  borrower: {
    email: 'hiro.nakamura@yopmail.com',
    fullName: 'Hiro Nakamura',
    phone: '404-404-4040',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 64895,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: 'PREQUAL',
    city: 'San Bruno',
    state: 'CA',
    zip: '94066',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Active Test Folder',
    currentMilestone: 'Funding',
    loanNumber: '99966461535DEV',
    fastTrackEligibility: 'ineligible',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 890000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: 'j322oi3-7d2e-364j-89bb99235-46866e86w',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000013',
    purchasePriceAmount: 1149000,
  },
  team: {
    loanOfficer: generateLoMattCibula(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    disclosuresSigned: null,
    referral: days36ago,
    preApproval: days17ago,
    applicationComplete: null,
    applicationStarted: days18ago,
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: null,
    loanScheduledClosing: null,
    respa: days45ago,
    submittedToUnderwriting: null,
    conditionalApproval: null,
    powerBidSubmitted: null,
    finalApprovalCtc: null,
    appraisalReceived: null,
  },
  consent: {
    borrower: '' as BorrowerShareWithAgentConsent,
  },
};

const underwriting1: LoanApiType = {
  borrower: {
    email: 'Nalani.akana@yopmail.com',
    fullName: 'Nalani Akana',
    phone: '808-525-3352',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '1537 Saint Louis Dr',
    city: 'Honolulu',
    state: 'HI',
    zip: '96816',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Active Test Folder',
    currentMilestone: 'Funding',
    loanNumber: '4989868168DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'Justin Enos',
    isEclosing: 'Hybrid Closing',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 770000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '1233n2l3523-e887-1686-98e6-1s6w8f4w68ef',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000014',
    purchasePriceAmount: 1100000,
  },
  team: {
    loanOfficer: generateLoMatthewBanasiak(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    referral: days18ago,
    preApproval: days17ago,
    applicationStarted: days17ago,
    respa: days17ago,
    applicationComplete: days16ago,
    submittedToUnderwriting: days15ago,
    appraisalOrdered: days14ago,
    conditionalApproval: days13ago,
    appraisalReceived: days12ago,
    finalApprovalCtc: null,
    scheduledCloseDate: null,
    closingPackageTitle: null,
    closed: null,
    rateLockExpDate: null,
    funded: null,
    loanScheduledClosing: days2ago,
    powerBidSubmitted: null,
  },
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
};

const underwriting2: LoanApiType = {
  borrower: {
    email: 'kimo.chang@yopmail.com',
    fullName: 'Kimo Chang',
    phone: '808-471-9686',
  },
  coborrower: {
    email: null,
    fullName: 'Joanne Chang',
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '95-225 Kaukini Pl #42',
    city: 'Mililani',
    state: 'HI',
    zip: '96789',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Active Test Folder',
    currentMilestone: 'Funding',
    loanNumber: '89894686DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'Zelda Medina',
    isEclosing: 'Hybrid Closing',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 910000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '4686we6864f6-86a68-16168-89e86w-168w6e8f6w8e4',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000015',
    purchasePriceAmount: 1300000,
  },
  team: {
    loanOfficer: generateLoMattTierney(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    referral: days13ago,
    preApproval: days12ago,
    applicationStarted: days12ago,
    respa: days12ago,
    applicationComplete: days13ago,
    submittedToUnderwriting: days12ago,
    appraisalOrdered: days11ago,
    conditionalApproval: days10ago,
    appraisalReceived: null,
    finalApprovalCtc: null,
    scheduledCloseDate: null,
    closingPackageTitle: null,
    closed: null,
    rateLockExpDate: null,
    funded: null,
    loanScheduledClosing: null,
    powerBidSubmitted: null,
  },
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
};

const closing1: LoanApiType = {
  borrower: {
    email: 'Madeleine.Allakariallak@yopmail.com',
    fullName: 'Madeleine Allakariallak',
    phone: '206-643-9763',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '489 Sally J Rd',
    city: 'Mount Vernon',
    state: 'KY',
    zip: '40456',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Active Test Folder',
    currentMilestone: 'Funding',
    loanNumber: '980121969604DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'Stef Adams',
    isEclosing: 'Hybrid Closing',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 460000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '25903a56-a3f8-4538-94e2-3e4df4dddf52',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000016',
    purchasePriceAmount: 575000,
  },
  team: {
    loanOfficer: generateLoLorrainePiechnik(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    referral: days10ago,
    preApproval: days9ago,
    applicationComplete: days8ago,
    respa: days9ago,
    submittedToUnderwriting: days7ago,
    appraisalOrdered: days6ago,
    conditionalApproval: days5ago,
    appraisalReceived: days4ago,
    finalApprovalCtc: days3ago,
    scheduledCloseDate: today,
    closingPackageTitle: days2ago,
    closed: null,
    applicationStarted: days46ago,
    rateLockExpDate: null,
    funded: null,
    loanScheduledClosing: days2ago,
    powerBidSubmitted: null,
  },
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
};

const closing2: LoanApiType = {
  borrower: {
    email: 'Darareaksmey.Saeteurn@yopmail.com',
    fullName: 'Darareaksmey Saeteurn',
    phone: '789-989-2232',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '6423 Langley Ct',
    city: 'Fort Wayne',
    state: 'IN',
    zip: '46815',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Active Test Folder',
    currentMilestone: 'Funding',
    loanNumber: '980121970589DEV',
    fastTrackEligibility: 'ineligible',
    fileStarter: 'Stef Adams',
    isEclosing: 'Hybrid Closing',
  },
  loanDetails: {
    loanPurpose: null,
    loanAmount: 239200,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '95c3d793-e815-48f6-823e-8b8c4eabde7d',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000017',
    purchasePriceAmount: 299000,
  },
  team: {
    loanOfficer: generateLoLiLi(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    referral: days10ago,
    preApproval: days9ago,
    applicationComplete: days8ago,
    respa: days9ago,
    submittedToUnderwriting: days7ago,
    appraisalOrdered: days6ago,
    conditionalApproval: days5ago,
    appraisalReceived: days4ago,
    finalApprovalCtc: days7ago,
    scheduledCloseDate: null,
    closingPackageTitle: null,
    closed: null,
    applicationStarted: days46ago,
    rateLockExpDate: null,
    funded: null,
    loanScheduledClosing: days14future,
    powerBidSubmitted: null,
  },
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
};

// closed loans
const funded1: LoanApiType = {
  borrower: {
    email: 'fyodor.kuznetsov@yopmail.com',
    fullName: 'Fyodor Kuznetsov',
    phone: '7946681653',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '1142 Plantation Dr SE',
    city: 'Conyers',
    state: 'GA',
    zip: '30094',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Closed Loans',
    currentMilestone: 'Archived',
    loanNumber: '4135356815DEV',
    fastTrackEligibility: 'ineligible',
  },
  loanDetails: {
    loanPurpose: 'Purchase',
    loanAmount: 220000,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: '5ce452cb-303f-43f4-8624-69deab59bcd9',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000018',
    purchasePriceAmount: 275000,
  },
  team: {
    loanOfficer: generateLoJennyLewis(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    closed: yesterday,
    scheduledCloseDate: yesterday,
    disclosuresSigned: null,
    applicationComplete: null,
    applicationStarted: days45ago,
    appraisalOrdered: null,
    rateLockExpDate: null,
    funded: today,
    loanScheduledClosing: yesterday,
    respa: days9ago,
    submittedToUnderwriting: days5ago,
    conditionalApproval: days5ago,
    powerBidSubmitted: null,
    finalApprovalCtc: yesterday,
    appraisalReceived: null,
  },
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
};

const funded2: LoanApiType = {
  borrower: {
    email: 'tullio.adorni@yopmail.com',
    fullName: 'Tullio Adorni',
    phone: '8984641657',
  },
  coborrower: {
    email: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: null,
      id: 26149,
    },
    sellers: {
      email: null,
    },
  },
  property: {
    address: '56407 Sylvan Medows Ln',
    city: 'Bristol',
    state: 'IN',
    zip: '46507',
  },
  otherLoanInfo: {
    actionTaken: 'Loan Originated',
    loanFolder: 'Closed Loans',
    currentMilestone: 'Archived',
    loanNumber: '894916547DEV',
    fastTrackEligibility: 'ineligible',
  },
  loanDetails: {
    loanPurpose: 'Purchase',
    loanAmount: 695920,
    appraisalReq: true,
    preapproval: false,
    encompassLoanGuid: 'b8689ee0-99e8-4531-bbf7-bd23aweoifj2o38982',
    grLoanGuid: 'f3cb1273-9a07-452d-96fe-000000000019',
    purchasePriceAmount: 869900,
  },
  team: {
    loanOfficer: generateLoJenniferSchenk(),
    loanCoordinator: generateLcTestLc(),
    mortgageConsultant: generateMcTestMc(),
    salesAssistant: generateSaTestSa(),
    productionManager: generatePmTestPm(),
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    referral: days72ago,
    respa: days71ago,
    preApproval: days70ago,
    applicationStarted: days69ago,
    applicationComplete: days69ago,
    submittedToUnderwriting: days68ago,
    appraisalOrdered: days67ago,
    conditionalApproval: days66ago,
    appraisalReceived: days65ago,
    finalApprovalCtc: days64ago,
    scheduledCloseDate: days63ago,
    loanScheduledClosing: days63ago,
    closingPackageTitle: days63ago,
    closed: days62ago,
    disclosuresSigned: null,
    rateLockExpDate: null,
    funded: days61ago,
    powerBidSubmitted: null,
  },
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
};

const testLoans = (): LoanApiType[] => [
  inProgress1,
  preApproval1,
  preApproval2,
  underwriting1,
  underwriting2,
  closing1,
  closing2,
  funded1,
  funded2,
];

const referral1 = {
  consent: {
    borrower: 1 as BorrowerShareWithAgentConsent,
  },
  team: {
    loanCoordinator: {},
    loanOfficer: {
      email: 'testlo@rate.com',
      nmls: '1234567',
      phone: '555-555-5555',
      photoUrl: 'https://www.guaranteedrate.com/images/vp/testlo.jpg',
      title: 'LO',
      employeeActive: true,
      id: '12657',
      name: 'Test LO',
    },
    mortgageConsultant: {},
    productionManager: {},
    salesAssistant: {},
    closingCoordinator: {},
  },
  disposition: {
    current: 'Does not qualify',
    history: null,
    error: null,
  },
  otherLoanInfo: {
    actionTaken: '',
    fastTrackEligibility: null,
    loanNumber: '',
    isEclosing: null,
    piwChecked: null,
    currentMilestone: '',
    loanFolder: 'POS Leads Temp',
  },
  loanDetails: {
    loanPurpose: 'Purchase',
    loanAmount: null,
    purchasePriceAmount: null,
    appraisalReq: true,
    preapproval: false,
    grLoanGuid: 'bb8b01d9-b068-4a34-a64c-d9c259066a21',
  },
  borrower: {
    email: 'dispositions.test.borrower.1@yopmail.com',
    firstName: 'Areferral',
    lastName: 'Potentialborrower',
    fullName: null,
    phone: '312-554-9914',
  },
  coborrower: {
    email: null,
    firstName: null,
    lastName: null,
    fullName: null,
    phone: null,
  },
  agents: {
    buyers: {
      email: 'agents2.dispositions@yopmail.com',
      name: null,
      id: 100210,
    },
    sellers: {
      email: null,
      name: null,
    },
  },
  company: 'GRI' as CompanyType,
  loanStatusDates: {
    closed: null,
    scheduledCloseDate: null,
    preApproval: null,
    respa: null,
    closingPackageTitle: null,
    disclosuresSigned: null,
    applicationComplete: null,
    applicationStarted: null,
    appraisalOrdered: null,
    referral: '2024-11-20T20:09:49+0000',
    rateLockExpDate: null,
    funded: null,
    submittedToUnderwriting: null,
    conditionalApproval: null,
    powerBidSubmitted: null,
    finalApprovalCtc: null,
    lastDispositionDate: null,
    appraisalReceived: null,
    loanScheduledClosing: null,
  },
};

export {
  referral1,
  dmxLoan,
  fundedLoan1,
  loanAppraisal,
  loanByLO,
  loanElectronicClose,
  loanHybridElectronicClose,
  loanMilestones,
  loanPiw,
  loanUnderContract,
  loanUnderwriting,
  sampleLoanData,
  inProgress1,
  preApproval1,
  preApproval2,
  underwriting1,
  underwriting2,
  closing1,
  closing2,
  funded1,
  funded2,
  testLoans,
  testDocuments,
};
