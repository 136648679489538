import {
  AbridgedLoanOfficer,
  AgentUserStats,
  MarketingRequest,
  MLSAssociation,
} from 'types';

export enum ActionType {
  SET_LASTAUTHCHECK = 'SET_LASTAUTHCHECK',
  SET_LOANS_ERROR = 'SET_LOANS_ERROR',
  SET_LOANS_LOADING = 'SET_LOANS_LOADING',
  SET_LOAN_STATS = 'SET_LOAN_STATS',
  SET_UNIQUE_LOAN_OFFICERS = 'SET_UNIQUE_LOAN_OFFICERS',
  LOGIN = 'LOGIN',
  LOGOUT = 'LOGOUT',
  ADD_LO = 'ADD_LO',
  MARKETING_REQUEST = 'MARKETING_REQUEST',
  SET_MLS_ASSOCIATIONS = 'SET_MLS_ASSOCIATIONS',
  SET_USER_LOADING = 'SET_USER_LOADING',
}

export type Action =
  | { type: ActionType.SET_LOANS_ERROR; payload: Error }
  | { type: ActionType.SET_LOANS_LOADING; payload: boolean }
  | { type: ActionType.SET_LOAN_STATS; payload: AgentUserStats }
  | { type: ActionType.SET_USER_LOADING; payload: boolean }
  | {
      type: ActionType.SET_MLS_ASSOCIATIONS;
      mlsAssociations: MLSAssociation[];
    }
  | {
      type: ActionType.ADD_LO;
      payload: AbridgedLoanOfficer;
    }
  | {
      type: ActionType.SET_UNIQUE_LOAN_OFFICERS;
      uniqueActiveLoanOfficers: AbridgedLoanOfficer[];
    }
  | {
      type: ActionType.MARKETING_REQUEST;
      payload: MarketingRequest;
    }
  | {
      type: ActionType.SET_LASTAUTHCHECK;
      payload: number;
    }
  | { type: ActionType.LOGOUT };
