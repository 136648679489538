import { useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  SearchField,
  Button,
  Spinner,
  LoanOfficerCard,
  Header1,
  Alert,
  Container,
} from 'Components';
import { useWindowDimensions } from 'lib/hooks';
import { loanOfficers as loApi } from 'api';
import { LoanOfficer, SelectedLoanOfficer } from 'types';
import { tabletDimension } from 'Components/Common/Utils';
import useAppContext from 'contexts/AppContext';

const LoanOfficerSearch = (props: {
  backbuttonclick: () => void;
  setSelectedLO: (lo: SelectedLoanOfficer) => void;
}) => {
  const { height, width } = useWindowDimensions();
  const { isRunningInShellApp } = useAppContext();
  const [search, setSearch] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');
  const [loanOfficers, setLoanOfficers] = useState<LoanOfficer[]>([]);
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [pageNumber, setPageNumber] = useState<number>(0);
  const { backbuttonclick, setSelectedLO } = props;

  const doSearch = async (isInitialSearch: boolean) => {
    try {
      if (search) {
        const offset = isInitialSearch ? 0 : pageNumber;
        setLoading(true);
        setShowAlert(false);
        const results = await loApi.search({
          searchTerm: search,
          offset: (offset * 20).toString(),
        });
        if (results.ok && results.loanOfficers) {
          if (!results.loanOfficers.length) {
            setShowAlert(true);
            setAlertMessage(
              'No loan officers were found. Please revise your search.'
            );
            setHasMore(false);
            setPageNumber(0);
          } else {
            setHasMore(true);
            setLoanOfficers(
              isInitialSearch
                ? results.loanOfficers
                : [...loanOfficers, ...results.loanOfficers]
            );
            setPageNumber(pageNumber + 1);
          }
        } else if (results.error) {
          const errorMessage = results.serverError?.errors?.[0]?.title;
          setAlertMessage(errorMessage || 'Error retrieving loan officer data');
          setShowAlert(true);
        }
      } else {
        setShowAlert(false);
      }
    } catch (error) {
      console.log(JSON.stringify(error));
      setAlertMessage('Error retrieving loan officer data');
      setShowAlert(true);
    } finally {
      setLoading(false);
    }
  };
  const backButtonClick = () => {
    setLoanOfficers([]);
    backbuttonclick();
    setLoading(false);
    setShowAlert(false);
  };
  const minHeight = useMemo(() => {
    if (width && width <= tabletDimension) {
      // Adjust height based on whether the app is running in a shell
      return height - (isRunningInShellApp ? 240 : 337); // Subtract 240px for shell apps to exclude footer and header from native screens
    }
    return 'auto';
  }, [width, height, isRunningInShellApp]);
  return (
    <div>
      <Header1 classes={['tw-my-0 md:!tw-leading-14 tw-mb-2 md:tw-mb-6']}>
        Find a loan officer
      </Header1>
      <Container
        containerPaddingAll={false}
        largeScreenWidth=""
        className="md:tw-px-26 md:tw-py-6"
      >
        <p className="tw-m-0">Search by name, city, state or zip code.</p>
        <div className="tw-pt-4 md:tw-pt-6">
          <SearchField
            searchKey={(e) => {
              setSearch(e);
              setPageNumber(0);
            }}
            doSearch={() => {
              setLoanOfficers([]);
              setHasMore(true);
              doSearch(true);
            }}
            classsNames="tw-w-96"
          />
          <div className="tw-flex tw-flex-col tw-items-center tw-pt-4">
            {showAlert && !loanOfficers.length && (
              <Alert
                type="inline"
                severity="error"
                show={showAlert}
                showClasses={['md:tw-w-full', 'tw-items-center', 'tw-mb-5']}
                message={alertMessage}
              />
            )}
            {loading && (
              <div className="tw-flex tw-justify-center tw-mb-5">
                <Spinner type="quaternary" />
              </div>
            )}

            {!loanOfficers.length && (
              <Button
                type="primary"
                fullWidth={true}
                onClick={() => {
                  doSearch(true);
                }}
                isDisabled={!search || loading}
                className={[
                  'tw-gap-1',
                  'tw-py-3',
                  'tw-h-auto',
                  'tw-px-16',
                  'tw-w-full',
                  'tw-text-xl',
                  'md:tw-w-48',
                ]}
              >
                Next
              </Button>
            )}
          </div>
        </div>
      </Container>

      <InfiniteScroll
        dataLength={loanOfficers.length}
        next={() => {
          doSearch(false);
        }}
        hasMore={hasMore}
        loader={<div />}
      >
        <div
          style={{ minHeight }}
          className="tw-flex tw-flex-col tw-py-5 md:tw-py-6 tw-gap-5"
        >
          {loanOfficers &&
            loanOfficers.length > 0 &&
            loanOfficers.map((lo, index) => (
              <LoanOfficerCard
                key={`${lo?.employeeId}-${index}`}
                loanOfficer={lo}
                backclick={backButtonClick}
                setSelectedLO={setSelectedLO}
              />
            ))}
        </div>
      </InfiniteScroll>
    </div>
  );
};

export { LoanOfficerSearch };
