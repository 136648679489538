import { Button } from 'Components';
import useAppContext from 'contexts/AppContext';
import { sendMessageToShellApp } from 'lib/shell-app';
import { useNavigate } from 'react-router-dom';

const ShareAndMarketingButtons = () => {
  const { isRunningInShellApp } = useAppContext();
  const navigate = useNavigate();
  return (
    <>
      <div className="tw-flex tw-flex-row tw-gap-4 tw-w-full md:tw-w-auto md:tw-gap-2">
        <Button
          type="secondary"
          onClick={() => {
            isRunningInShellApp
              ? sendMessageToShellApp({ type: 'SHARE_APP' })
              : navigate('/dashboard/rate');
          }}
          className={['tw-w-full md:tw-w-234 md:!tw-px-3']}
          dataTestId="share-app-button"
        >
          <span className="tw-block md:tw-hidden">Share Rate app</span>
          <span className="tw-hidden md:tw-block">Share the Rate app!</span>
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            isRunningInShellApp
              ? sendMessageToShellApp({ type: 'OPEN_AGENTS_MARKETING' })
              : navigate('/marketing-request');
          }}
          className={['tw-w-full md:tw-w-234 md:!tw-px-3']}
          dataTestId="marketing-button"
        >
          <span className="tw-block md:tw-hidden">Marketing</span>
          <span className="tw-hidden md:tw-block">
            Request marketing materials
          </span>
        </Button>
      </div>
      {/* add share rate app dialog component */}
    </>
  );
};

export { ShareAndMarketingButtons };
