import { useMemo } from 'react';
import { get } from 'lodash';
import classNames from 'classnames';

import { Address, CircleAvatar } from 'Components';
import { LoanStatusDates, LoanType } from 'types';

import { capitalCase } from 'lib/string';
import {
  formatBorrowerDisplayName,
  getFormattedShortStage,
  isReferralConsentNotDenied,
  loanIsInProgress,
} from 'lib/loans';

// import useAppContext from 'contexts/AppContext';
// import useCurrentUser from 'contexts/UserContext';
// import { dispatchTrackingEvent } from 'lib/tracking';
import { numberFormatOptions } from 'lib/util';
import { getLatest, humanizeDateTime } from 'lib/date';
import { BorrowerConsentAlert } from 'Components/BorrowerConsentAlert/BorrowerConsentAlert';
import { ShellAppHandleLoanDetailMessage } from 'lib/shell-app';
import { useNavigate } from 'react-router-dom';
import useAppContext from 'contexts/AppContext';
import { sendMessageToShellApp } from 'lib/shell-app';

import { ContactLine } from './ContactLine';

import { ReactComponent as ChevronRight } from 'Assets/Images/chevron-right-1.svg';

// import PowerBidLogo from 'Assets/logos/PowerBid-logo.svg';
// import SameDayMortgageLogo from 'Assets/logos/same-day-mortgage.png';
// import { ReactComponent as FacebookIcon } from 'Assets/Images/SocialMedia/Facebook-blue.svg';

// import {
//   ShellAppFacebookShareLinkMessage,
//   sendMessageToShellApp,
// } from 'lib/shell-app';

type MiniLoanOfficer = {
  displayName: string;
  photoUrl: string;
};

const formatUpdatedBlurb = (
  loanStatusDates: LoanStatusDates,
  isInprogress: boolean
): string => {
  const latestDate = getLatest(loanStatusDates, isInprogress);
  if (latestDate) {
    return (
      (isInprogress ? 'Updated ' : 'Closed on ') + humanizeDateTime(latestDate)
    );
  }
  return '';
};

type LoanSummaryTileProps = {
  loan: LoanType;
  active?: boolean;
  mobileFullWidth?: boolean;
  className?: string;
  isSendMessageToShellApp?: boolean;
  totalLoans?: number;
  mode?: 'narrow' | 'extraNarrow' | 'wide';
  verticalGap?: boolean;
};

const LoanSummaryTile = ({
  loan,
  active = true,
  mobileFullWidth = false,
  className = '',
  isSendMessageToShellApp,
  mode = 'narrow',
  verticalGap = false,
}: LoanSummaryTileProps) => {
  const {
    borrower,
    coborrower,
    loanStatusDates = {},
    status,
    property,
    // otherLoanInfo,
    team,
    consent,
  } = loan;
  // const { isRunningInShellApp } = useAppContext();
  // const { user } = useCurrentUser();

  const guid = loan?.loanDetails?.grLoanGuid || 'unknown';
  let pathname = '/closings/' + guid;
  const closingsMessage: ShellAppHandleLoanDetailMessage = {
    type: 'OPEN_AGENTS_CLOSINGS_LOAN_DETAIL',
    payload: guid,
  };
  const fundedDate = get(loan, 'loanStatusDates.funded');
  const isFunded = !!fundedDate;

  // const fundedDate = useMemo(
  //   () => formatDatetime(get(loan, 'loanStatusDates.funded'), true),
  //   [loan]
  // );
  const { isRunningInShellApp, setIsPrevUrlDashboard } = useAppContext();
  const navigate = useNavigate();

  const lastCompletedStageFormatted = useMemo(() => {
    return getFormattedShortStage(loan, status?.lastCompletedStage || '');
  }, [status, loan]);

  const getDisposition = (loan: LoanType): string =>
    loan?.status?.currentMilestone === 'referral' && loan?.disposition?.current
      ? loan.disposition.current
      : '';

  const dispositionStatusFormatted = useMemo(
    () => getDisposition(loan),
    [loan]
  );

  // const [facebookShareButtonProps, setFacebookShareButtonProps] =
  //   useState<ButtonProps>({
  //     type: 'tertiary',
  //     className: ['tw-flex tw-items-center tw-gap-1 tw-mb-1'],
  //     children: (
  //       <>
  //         <span>Share the news</span>
  //         <FacebookIcon />
  //       </>
  //     ),
  //   });

  const isInProgress = useMemo(() => loanIsInProgress(loan), [loan]);

  const consentValue =
    consent?.borrower === undefined ? null : consent.borrower;

  const showFullDetails = isFunded || consentValue === 1;

  const isOpenReferral = isReferralConsentNotDenied(loan);

  const isFullOrOpenReferral = showFullDetails || isOpenReferral;

  const loanOfficer: MiniLoanOfficer = useMemo(
    () =>
      isFullOrOpenReferral && team?.loanOfficer && team?.loanOfficer?.id
        ? {
            displayName:
              team && team?.loanOfficer && team?.loanOfficer.name
                ? team?.loanOfficer.name
                : '',
            photoUrl: team?.loanOfficer?.photoUrl || '',
          }
        : {
            displayName: '',
            photoUrl: '',
          },
    [isFullOrOpenReferral, team]
  );

  // const isSameDayMortgageEligible = useMemo(
  //   () =>
  //     otherLoanInfo &&
  //     otherLoanInfo?.fastTrackEligibility &&
  //     otherLoanInfo.fastTrackEligibility !== 'ineligible',
  //   [otherLoanInfo]
  // );

  // const isPowerBid = useMemo(
  //   () =>
  //     loan.loanStatusDates?.powerBidSubmitted &&
  //     loan.loanStatusDates.powerBidSubmitted !== '',
  //   [loan]
  // );

  // const sendEventToAdobe = () => {
  //   dispatchTrackingEvent({
  //     eventName: 'shareFundedLoanFacebook',
  //     borrower: { email: borrower.email, name: borrower.name },
  //     agent: {
  //       email: user?.email,
  //       name: `${user?.firstName} ${user?.lastName}`,
  //     },
  //     loanOfficer: {
  //       ...(loan && loan?.team ? loan.team.loanOfficer : {}),
  //       ...{
  //         company: loan?.company || '',
  //       },
  //     },
  //     fundedDate,
  //     property: loan?.property,
  //     loan: {
  //       number: otherLoanInfo?.loanNumber,
  //       folder: otherLoanInfo?.loanFolder,
  //     },
  //   });
  // };

  // useEffect(() => {
  //   const messageParams = {
  //     app_id: '620695226433216',
  //     href: 'https://www.rate.com/sold',
  //     hashtag: '#closed',
  //   };
  //   if (isRunningInShellApp) {
  //     setFacebookShareButtonProps({
  //       ...facebookShareButtonProps,
  //       ...{
  //         onClick: () => {
  //           sendEventToAdobe();
  //           sendMessageToShellApp({
  //             ...{
  //               type: 'FACEBOOK_SHARE_LINK',
  //             },
  //             ...messageParams,
  //           } as ShellAppFacebookShareLinkMessage);
  //         },
  //       },
  //     });
  //   } else {
  //     const facebookShareParams: { [key: string]: string } = {
  //       ...{
  //         display: 'popup',
  //         redirect_uri:
  //           (window.location.host.toLowerCase() === 'localhost:3001'
  //             ? 'http'
  //             : 'https') + `://${window.location.host}/loan-status`,
  //       },
  //       ...messageParams,
  //     };
  //     const searchParams = new URLSearchParams(facebookShareParams);
  //     setFacebookShareButtonProps({
  //       ...facebookShareButtonProps,
  //       ...{
  //         externalHref:
  //           'https://www.facebook.com/dialog/share?' + searchParams.toString(),
  //         targetBlank: true,
  //         onClick: sendEventToAdobe,
  //       },
  //     });
  //   }
  // }, [isRunningInShellApp]);

  const borrowerDisplayName = useMemo(
    () =>
      formatBorrowerDisplayName(
        borrower?.fullName || '',
        borrower?.firstName || '',
        borrower?.lastName || ''
      ),
    [borrower]
  );
  const coBorrowerDisplayName = useMemo(
    () =>
      formatBorrowerDisplayName(
        coborrower?.fullName || '',
        coborrower?.firstName || '',
        coborrower?.lastName || ''
      ),
    [coborrower]
  );

  const updatedBlurb = useMemo(() => {
    return formatUpdatedBlurb(loanStatusDates, isInProgress);
  }, [loanStatusDates, isInProgress]);

  const content = isFullOrOpenReferral ? (
    <div className="tw-h-full tw-flex tw-flex-col tw-justify-between">
      <div className="tw-pl-6 tw-pr-3 tw-flex tw-w-full tw-items-start tw-justify-between">
        <div className="tw-flex tw-flex-row">
          {!!lastCompletedStageFormatted && (
            <p data-testid="loan-summary-last-completed-stage">
              <span className="tw-font-bold tw-leading-3 tw-text-sm tw-bg-gray-ravenswood tw-text-white tw-rounded tw-px-1 tw-py-[2px]">
                {lastCompletedStageFormatted}
              </span>
            </p>
          )}
          {!!dispositionStatusFormatted && (
            <p data-testid="loan-summary-last-disposition-status">
              <span className="tw-font-bold tw-leading-3 tw-ml-2 tw-border tw-border-gray-ravenswood tw-text-sm tw-rounded tw-px-1 tw-py-[2px]">
                {dispositionStatusFormatted}
              </span>
            </p>
          )}
        </div>
        {active && <ChevronRight className="tw-mr-2" />}
      </div>
      <div
        className={classNames(
          'tw-h-full tw-px-6 tw-flex tw-flex-col tw-justify-between',
          {
            'tw-justify-between': loanOfficer.displayName,
            'tw-gap-2': verticalGap,
          }
        )}
      >
        <h2
          className="tw-font-bold tw-text-xl tw-text-gray-ravenswood tw-m-0"
          data-testid="loan-summary-purchase-price"
        >
          {!!loan.loanDetails?.purchasePriceAmount &&
            new Intl.NumberFormat('en-US', numberFormatOptions).format(
              loan.loanDetails?.purchasePriceAmount || 0
            )}
        </h2>
        <div>
          <h4
            className="tw-text-base tw-font-bold tw-truncate tw-m-0"
            data-testid="loan-summary-borrower"
          >
            {borrowerDisplayName}
          </h4>
          {isOpenReferral && (
            <ContactLine
              email={borrower?.email}
              phone={borrower?.phone}
              mode={mode}
            />
          )}
          {coborrower && coBorrowerDisplayName && !isOpenReferral && (
            <h4
              className="tw-text-base tw-font-bold tw-truncate tw-m-0"
              data-testid="loan-summary-coborrower"
            >
              {coBorrowerDisplayName}
            </h4>
          )}
          {!isOpenReferral && (
            <div className="tw-text-base" data-testid="loan-summary-property">
              <Address
                emptyString="Pending sales contract"
                property={property}
              />
            </div>
          )}
        </div>
        {updatedBlurb && (
          <p
            className="tw-text-xs tw-m-0"
            data-testid="loan-summary-updated-date"
          >
            {updatedBlurb}
          </p>
        )}
        {loanOfficer.displayName && (
          <div className="tw-flex tw-flex-row tw-gap-2 tw-text-center tw-items-center ">
            {process.env.NODE_ENV !== 'test' && (
              <CircleAvatar
                photoUrl={(loanOfficer && loanOfficer.photoUrl) || ''}
                alt={(loanOfficer && loanOfficer.displayName) || ''}
                size={8}
                className="group-hover:tw-scale-110 tw-transition-transform tw-duration-200"
              />
            )}
            <span className="tw-text-gray-ravenswood tw-text-base tw-text-left">
              Working with {loanOfficer.displayName}
            </span>
          </div>
        )}
        {/* {(isFunded || isPowerBid || isSameDayMortgageEligible) && (
            <div className="tw-absolute tw-right-4 tw-bottom-4 tw-flex tw-flex-row tw-gap-3">
              <div>{isFunded && <Button {...facebookShareButtonProps} />}</div>
              {isPowerBid ? (
                <div className="tw-justify-self-end">
                  <img
                    src={PowerBidLogo}
                    className="tw-h-6"
                    data-testid="powerbid-logo"
                    alt="PowerBid Approval"
                  />
                </div>
              ) : isSameDayMortgageEligible ? (
                <div className="tw-justify-self-end">
                  <img
                    src={SameDayMortgageLogo}
                    className="tw-h-6"
                    data-testid="same-day-mortgage-logo"
                    alt="Same Day Mortgage"
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          )} */}

        {isOpenReferral && (
          <BorrowerConsentAlert
            loan={loan}
            severity="warning"
            showClasses={['tw-mt-3']}
            mode={mode}
          />
        )}
      </div>
    </div>
  ) : (
    <div className="tw-px-6 tw-h-full">
      <div className="tw-flex tw-flex-col tw-gap-3 tw-justify-between tw-h-full">
        <div className="tw-flex tw-flex-col tw-gap-2">
          <p>
            <span className="tw-font-bold tw-leading-3 tw-text-sm tw-text-white tw-bg-gray-ravenswood tw-rounded tw-px-1 tw-py-[2px]">
              {isInProgress ? 'Upcoming closing' : 'Closed'}
            </span>
          </p>
          {property?.city && property?.state && (
            <h1 className="tw-font-bold tw-text-xl tw-text-gray-ravenswood">
              {capitalCase(property?.city || '')}, {property?.state}
            </h1>
          )}
        </div>
        <BorrowerConsentAlert
          loan={loan}
          severity="warning"
          showClasses={['tw-mt-3']}
        />
      </div>
    </div>
  );

  if (!isFullOrOpenReferral) {
    return (
      <div
        className={`tw-border tw-border-gray-neutral tw-py-4 tw-rounded tw-bg-white ${className}`}
        data-testid={`closing-summary-${guid}`}
        role="gridcell"
      >
        {content}
      </div>
    );
  }
  return (
    <button
      onClick={() => {
        if (isRunningInShellApp && isSendMessageToShellApp && closingsMessage) {
          setIsPrevUrlDashboard(true);
          sendMessageToShellApp(closingsMessage);
        } else {
          navigate(pathname);
        }
      }}
      data-testid={`closing-summary-${guid}`}
      role="gridcell"
    >
      <div
        className={`tw-h-full tw-border tw-border-gray-neutral tw-pt-5 tw-pb-4 tw-rounded tw-cursor-pointer hover:tw-border-bluegrey-hoverborder hover:tw-bg-bluegrey-hover active:tw-scale-95 tw-transition tw-duration-75 tw-bg-white tw-text-left ${className}`}
      >
        {content}
      </div>
    </button>
  );
};

export { LoanSummaryTile };
