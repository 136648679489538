import { Suspense, useEffect, useState } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { lazily } from 'react-lazily';

import { LoadingPage, NoMatch } from './Pages';

import useAppContext from 'contexts/AppContext';
import useCurrentUser from 'contexts/UserContext';

import type { AuthStateType } from 'types';

import { isAuthenticated } from 'lib/okta';
import { CurrentLoanProvider } from 'contexts/CurrentLoanContext';
import { users as usersApi } from 'api';
import { RateAccountAlreadyExists } from 'Components/signup/RateAccountAlreadyExists';
import {
  SHELL_APP_TO_WEB_APP_MESSAGE_EVENT,
  ShellAppToWebAppMessageEvent,
} from 'lib/shell-app-receive';

type PrivateRouteProps = {
  children: JSX.Element;
  path?: string;
};

const { AgentHome } = lazily(() => import('./Pages/AgentHome'));
const { AuthenticatedHome } = lazily(
  () => import('./Pages/Home/AuthenticatedHome')
);
const { Calculator } = lazily(() => import('./Pages/Tools/Calculator'));
const { CheckYourInbox } = lazily(() => import('./Pages/CheckYourInbox'));
const { Closings } = lazily(() => import('./Pages/Closings'));
const { EditEmail } = lazily(() => import('./Pages/EditEmail'));
const { EditPassword } = lazily(() => import('./Pages/EditPassword'));
const { HelpAndSupport } = lazily(() => import('./Pages/HelpAndSupport'));
const { Home } = lazily(() => import('./Pages/Home/Home'));
const { LoanDetail } = lazily(() => import('./Pages/LoanDetail'));
const { LoginPage } = lazily(() => import('./Pages/Login'));
const { LoggingInPage } = lazily(() => import('./Pages/LoggingIn'));
const { LoggingOutPage } = lazily(() => import('./Pages/LoggingOut'));
const { Marketing } = lazily(() => import('./Pages/Marketing'));
const { OpenHouseLanding } = lazily(() => import('./Pages/OpenHouse/Landing'));
const { ProfessionalProfile } = lazily(
  () => import('./Pages/ProfessionalProfile')
);
const { ProfessionalProfileForm } = lazily(
  () => import('./Pages/ProfessionalProfile/ProfessionalProfileForm')
);
const { ReferAClient } = lazily(() => import('./Pages/ReferAClient'));
const { SignUpCompleteRegistration } = lazily(
  () => import('./Pages/SignUpCompleteRegistration')
);
const { SignUpEmailEntry } = lazily(() => import('./Pages/SignUpEmailEntry'));
const { Tools } = lazily(() => import('./Pages/Tools'));

const PrivateRoute = (props: PrivateRouteProps) => {
  const { children } = props;
  const location = useLocation();
  const {
    lastAuthCheck,
    clearLocalStorage,
    setLastAuthCheck,
    getLoans,
    loans,
    setUser,
    user,
  } = useCurrentUser();

  const CHECK_AUTH_INTERVAL = 60000; // check auth via whoami every minute
  const millisecSinceLastAuthCheck = Date.now() - lastAuthCheck;
  const shouldCheckAuth = millisecSinceLastAuthCheck > CHECK_AUTH_INTERVAL;
  const initAuthState: AuthStateType = shouldCheckAuth
    ? 'notStarted'
    : lastAuthCheck > 0
    ? 'authenticated'
    : 'notStarted';

  const [authState, setAuthState] = useState<AuthStateType>(initAuthState);
  const path = location.pathname || '';

  useEffect(() => {
    const checkAuth = async () => {
      setAuthState('checking');
      const authenticated = await isAuthenticated();
      if (authenticated) {
        if (!loans.length) {
          getLoans();
        }
        setLastAuthCheck(Date.now());
        if (typeof user === 'undefined') {
          const getUserResponse = await usersApi.getAgentUser();
          if (getUserResponse.ok && getUserResponse.agent) {
            setUser(getUserResponse.agent);
          }
        }
      } else {
        clearLocalStorage();
      }
      setAuthState(authenticated ? 'authenticated' : 'noauth');
    };
    if (authState === 'notStarted') {
      checkAuth();
    }
  }, [
    authState,
    clearLocalStorage,
    getLoans,
    loans.length,
    setLastAuthCheck,
    setAuthState,
    setUser,
    user,
  ]);

  return authState === 'notStarted' ||
    authState === 'checking' ? null : authState === 'noauth' ? (
    <Navigate to="/log-in" state={{ path }} />
  ) : (
    children
  );
};

const App = () => {
  const { detectShellApp } = useAppContext();

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    detectShellApp();
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    const handleShellAppToWebAppMessage = (event: Event) => {
      const customEvent = event as ShellAppToWebAppMessageEvent;
      const { detail } = customEvent;

      console.log(`received message from shell app`, detail);

      switch (detail.type) {
        case 'OPEN_AGENTS_CLOSINGS_LOAN_DETAIL': {
          return navigate(`/closings/${encodeURIComponent(detail.payload)}`);
        }
        default: {
          console.error(`unhandled message from shell app`, detail);
        }
      }
    };
    window.addEventListener(
      SHELL_APP_TO_WEB_APP_MESSAGE_EVENT,
      handleShellAppToWebAppMessage
    );
    return () => {
      window.removeEventListener(
        SHELL_APP_TO_WEB_APP_MESSAGE_EVENT,
        handleShellAppToWebAppMessage
      );
    };
  }, [navigate]);

  return (
    <Suspense fallback={<LoadingPage title="Loading" />}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/check-your-inbox" element={<CheckYourInbox />} />
        <Route path="/create-account" element={<SignUpEmailEntry />} />
        <Route
          path="/login-via-the-rate-app"
          element={<RateAccountAlreadyExists />}
        />
        <Route path="/verify-email" element={<SignUpCompleteRegistration />} />
        <Route path="/log-in" element={<LoginPage />} />
        <Route
          path="/logging-in"
          element={
            <PrivateRoute>
              <LoggingInPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/logging-out"
          element={
            <PrivateRoute>
              <LoggingOutPage />
            </PrivateRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            <PrivateRoute>
              <AgentHome />
            </PrivateRoute>
          }
        />
        <Route
          path="dashboard/rate"
          element={
            <PrivateRoute>
              <AuthenticatedHome />
            </PrivateRoute>
          }
        />
        <Route
          path="/help"
          element={
            <PrivateRoute>
              <HelpAndSupport />
            </PrivateRoute>
          }
        />
        <Route
          path="/closings"
          element={<Navigate to="/closings/in-progress" />}
        />
        <Route
          path="/closings/closed"
          element={
            <PrivateRoute>
              <Closings />
            </PrivateRoute>
          }
        />
        <Route
          path="/closings/in-progress"
          element={
            <PrivateRoute>
              <Closings />
            </PrivateRoute>
          }
        />
        <Route
          path="/closings/:loanGuid"
          element={
            <PrivateRoute>
              <CurrentLoanProvider>
                <LoanDetail />
              </CurrentLoanProvider>
            </PrivateRoute>
          }
        />
        <Route
          path="/change-password"
          element={
            <PrivateRoute>
              <EditPassword />
            </PrivateRoute>
          }
        />
        <Route
          path="/change-email"
          element={
            <PrivateRoute>
              <EditEmail />
            </PrivateRoute>
          }
        />
        <Route
          path="/marketing-request/*"
          element={
            <PrivateRoute>
              <Marketing />
            </PrivateRoute>
          }
        />
        <Route
          path="/open-house"
          element={
            <PrivateRoute>
              <OpenHouseLanding />
            </PrivateRoute>
          }
        />
        <Route
          path="/professional-profile"
          element={
            <PrivateRoute>
              <ProfessionalProfile />
            </PrivateRoute>
          }
        />
        <Route
          path="/professional-profile/form"
          element={
            <PrivateRoute>
              <ProfessionalProfileForm />
            </PrivateRoute>
          }
        />
        <Route
          path="/refer-a-client/*"
          element={
            <PrivateRoute>
              <ReferAClient />
            </PrivateRoute>
          }
        />
        <Route
          path="/tools"
          element={
            <PrivateRoute>
              <Tools />
            </PrivateRoute>
          }
        />
        <Route
          path="/tools/*"
          element={
            <PrivateRoute>
              <Calculator />
            </PrivateRoute>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </Suspense>
  );
};

export { App };
