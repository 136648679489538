import React from 'react';
import {
  Header3,
  TextField,
  PhoneField,
  StateSelect,
  MlsAutoComplete,
} from 'Components';
import { SelectChangeEvent } from '@mui/material';
import useCurrentUser from 'contexts/UserContext';
import { convertMlsAssociationsToOptionProps } from 'lib/convert';

export function AgentInfoSection({
  formik,
  disabled,
}: {
  formik: any;
  disabled?: boolean;
}) {
  const { mlsAssociations } = useCurrentUser();
  return (
    <div>
      <Header3>Agent info</Header3>
      <div className="tw-pb-3 md:tw-py-3 tw-mb-4">
        <div className="tw-pb-3 md:tw-py-3 tw-grid tw-grid-cols-2 tw-gap-6">
          <TextField
            id="profileFirstName"
            name="profileFirstName"
            label="First name"
            required
            inputProps={{
              'data-testid': 'dt-edit-profileFirstName',
            }}
            value={formik.values.profileFirstName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('profileFirstName', event.target.value);
            }}
            error={
              !!formik.errors.profileFirstName &&
              !!formik.touched.profileFirstName
            }
            helperText={
              formik.errors.profileFirstName && formik.touched.profileFirstName
                ? `${formik.errors.profileFirstName}`
                : ''
            }
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
          <TextField
            id="profileLastName"
            name="profileLastName"
            label="Last name"
            required
            inputProps={{
              'data-testid': 'dt-edit-profileLastName',
            }}
            value={formik.values.profileLastName}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('profileLastName', event.target.value);
            }}
            error={
              !!formik.errors.profileLastName &&
              !!formik.touched.profileLastName
            }
            helperText={
              formik.errors.profileLastName && formik.touched.profileLastName
                ? `${formik.errors.profileLastName}`
                : ''
            }
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </div>
        <div className="tw-py-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <TextField
            id="profileTitle"
            name="profileTitle"
            label="Title"
            inputProps={{
              'data-testid': 'dt-edit-profileTitle',
            }}
            value={formik.values.profileTitle}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('profileTitle', event.target.value);
            }}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
          <TextField
            id="profileBrokerage"
            name="profileBrokerage"
            label="Brokerage"
            inputProps={{
              'data-testid': 'dt-edit-profileBrokerage',
            }}
            value={formik.values.profileBrokerage}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('profileBrokerage', event.target.value);
            }}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
          <PhoneField
            id="cellPhone"
            name="cellPhone"
            label="Cell Phone"
            inputProps={{
              'data-testid': 'dt-edit-cellPhone',
            }}
            value={formik.values.cellPhone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('cellPhone', event.target.value);
            }}
            placeholder="(XXX) XXX-XXXX"
            error={!!formik.errors.cellPhone && !!formik.touched.cellPhone}
            helperText={
              formik.errors.cellPhone && formik.touched.cellPhone
                ? `${formik.errors.cellPhone}`
                : ''
            }
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
          <PhoneField
            id="officePhone"
            name="officePhone"
            label="Office phone"
            inputProps={{
              'data-testid': 'dt-edit-officePhone',
            }}
            placeholder="(XXX) XXX-XXXX"
            value={formik.values.officePhone}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('officePhone', event.target.value);
            }}
            error={!!formik.errors.officePhone && !!formik.touched.officePhone}
            helperText={
              formik.errors.officePhone && formik.touched.officePhone
                ? `${formik.errors.officePhone}`
                : ''
            }
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </div>
        <div className="tw-py-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <TextField
            id="profileAddress1"
            name="profileAddress1"
            label="Address 1"
            inputProps={{
              'data-testid': 'dt-edit-address1',
            }}
            value={formik.values.address1}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('address1', event.target.value);
            }}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
          <TextField
            id="profileAddress2"
            name="profileAddress2"
            label="Address 2"
            inputProps={{
              'data-testid': 'dt-edit-address2',
            }}
            value={formik.values.address2}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('address2', event.target.value);
            }}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </div>
        <div className="tw-py-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <TextField
            id="profileCity"
            name="profileCity"
            label="City"
            inputProps={{
              'data-testid': 'dt-edit-city',
            }}
            value={formik.values.city}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('city', event.target.value);
            }}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
          <div className="tw-grid tw-grid-cols-2 md:tw-gap-3 tw-gap-6">
            <StateSelect
              id="profileState"
              label="State"
              name="profileState"
              value={formik.values.profileState}
              onChange={(event: SelectChangeEvent<any>) => {
                formik.setFieldValue('profileState', event.target.value);
              }}
              onBlur={formik.handleBlur}
              dataTestId="dt-edit-profileState"
              disabled={disabled}
            />
            <TextField
              id="profileZip"
              name="profileZip"
              label="Zip"
              inputProps={{
                'data-testid': 'dt-edit-profileZip',
              }}
              value={formik.values.profileZip}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                formik.setFieldValue('profileZip', event.target.value);
              }}
              error={!!formik.errors.profileZip && !!formik.touched.profileZip}
              helperText={
                formik.errors.profileZip && formik.touched.profileZip
                  ? `${formik.errors.profileZip}`
                  : ''
              }
              onBlur={formik.handleBlur}
              disabled={disabled}
            />
          </div>
        </div>
        <div className="tw-py-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <TextField
            id="website"
            name="website"
            label="Website"
            inputProps={{
              'data-testid': 'dt-edit-website',
            }}
            value={formik.values.website}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('website', event.target.value);
            }}
            error={!!formik.errors.website && !!formik.touched.website}
            helperText={
              formik.errors.website && formik.touched.website
                ? `${formik.errors.website}`
                : ''
            }
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
          <MlsAutoComplete
            onSelect={(option: any) => {
              if (option) {
                formik.setFieldValue('mlsAssociation', {
                  value: option.id,
                  label: option.name,
                });
                formik.setFieldValue('mlsAssociationId', option.value);
              } else {
                formik.setFieldValue('mlsAssociationId', 0);
              }
            }}
            placeholder="MLS association"
            optionsList={convertMlsAssociationsToOptionProps(mlsAssociations)}
            mlsAssociationId={formik.values.mlsAssociationId}
            disabled={disabled}
          />
        </div>
        <div className="tw-py-3 tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6">
          <TextField
            id="profileMLSAgentId"
            name="profileMLSAgentId"
            label="MLS agent ID"
            inputProps={{
              'data-testid': 'dt-edit-profileMLSAgentId',
            }}
            value={formik.values.profileMLSAgentId}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              formik.setFieldValue('profileMLSAgentId', event.target.value);
            }}
            onBlur={formik.handleBlur}
            disabled={disabled}
          />
        </div>
      </div>
    </div>
  );
}
