import { ReactNode } from 'react';
import classNames from 'classnames';

const Header2 = (props: { children: ReactNode; classes?: string[] }) => (
  <h2
    className={classNames(
      'tw-font-bold',
      'tw-text-[32px]',
      'tw-text-gray-ravenswood',
      'tw-tracking-normal',
      ...(props.classes ?? [])
    )}
  >
    {props.children}
  </h2>
);

export { Header2 };
